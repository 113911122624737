import React from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ThemeProvider from 'styles/ThemeProvider';
import { GlobalStateProvider } from 'context/global-context';
import Home from 'scenes/Home/Scene';
import NotFound from 'scenes/shared/NotFound';
import ProtectedRoute from 'scenes/Home/ProtectedRoute'

// eslint-disable-next-line no-undef
const { CRD_GLOBAL: { APP_GOOGLE_ANALYTICS_ID } } = window;
ReactGA.initialize(APP_GOOGLE_ANALYTICS_ID,{
gaOptions: { cookieFlags: 'SameSite=None; Secure', cookieDomain: 'auto' }
});

const App = () => (
  <main>
    <Router>
      <GlobalStateProvider>
        <ThemeProvider>
          <Switch>
          <ProtectedRoute />
          </Switch>
        </ThemeProvider>
      </GlobalStateProvider>
    </Router>
  </main>
);

export default App;
