// eslint-disable-next-line no-undef
const { CONTACT_US_SERVICE_API_URL } = window.CRD_GLOBAL;

export function contactBranch(params) {
  const options = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return fetch(CONTACT_US_SERVICE_API_URL, options);
};
