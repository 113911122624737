import React, { useState, useContext, useRef } from 'react';
import {
  Flex, Box, Text
} from 'rebass';
import {
  Label,
  Input,
  Textarea
} from '@rebass/forms';
import isEmpty from 'lodash/isEmpty';
import { contactBranch } from 'services/contactUsService';
import { contactUsSubmitEvent } from 'services/analytics';
import { isSentSmsToggle } from 'actions';
import 'react-phone-number-input/style.css';
import { GlobalStateContext } from 'context/global-context';
import PhoneInput from '../SendPhone/PhoneInput/PhoneInput';
import Recaptcha from '../SendPhone/Recaptcha/Recaptcha';
import get from 'lodash/get';
import { ThemeContext } from 'styled-components';
import  SocialButton  from '../Button/SocialButton';
import useFontScaling from "../../../../hooks/useFontScaling";

const ContactUs = () => {
  // eslint-disable-next-line no-unused-vars
  const { state, state: { isSmsSent }, state: { location: { EmailID, name } }, dispatch } = useContext(GlobalStateContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [comment, setComment] = useState('');
  const [status, setFormStatus] = useState('');
  const colors = get(useContext(ThemeContext), 'colors', {});
  const elementRefs= useRef({});

  const isSentSmsMessageToggle = () => {
    dispatch(isSentSmsToggle());
  };

  const onSubmitContactBranch = async (params) => {
    let response;
    try {
      response = await contactBranch(params);

      if (response.status === 200) {
        setFormStatus('Your contact form has been successfully submitted');
        isSentSmsMessageToggle();
      } else if ((response.status === 400) || (response.status === 500)) {
        setFormStatus('Something went wrong. Please try again');
        isSentSmsMessageToggle();
      }

    } catch (err) {
      setFormStatus('Something went wrong. Please try again');
      isSentSmsMessageToggle();
    }
  }

  const onSubmitContactForm = () => {
    // eslint-disable-next-line no-undef
    const { NETWORK_ID } = window.CRD_GLOBAL;

    const isContactFormValid = !isEmpty(firstName)
      && !isEmpty(phoneNumber)
      && !isEmpty(userEmail)
      && !isEmpty(phoneNumber)
      && !isEmpty(comment);
    
    if (isContactFormValid) {
      contactUsSubmitEvent(NETWORK_ID);
      onSubmitContactBranch({
        NetworkId: NETWORK_ID,
        ToNumber: phoneNumber,
        FromEmail: userEmail,
        ToEmail: EmailID,
        SubjectMsg: `${firstName}${' - [LocatorSearch Contact Form]'}`,
        BodyMsg: comment
      });
    }
  };

  useFontScaling(elementRefs);

  return (
    <Flex
      flexDirection="column"
      css={{
        width: '335px',
        '@media all and (max-width: 620px)': {
          width: '100%'
        }
      }}
    >
      {!isSmsSent ? (
        <div>
          <Box
            as="form"
            onSubmit={e => e.preventDefault()}
            py={3}
          >
            <Box>
              <Label
                htmlFor="name"
                css={{
                  paddingBottom: 10
                }}
              >
                Your Name
                <span
                  style={{
                    color: 'red'
                  }}
                >
*
                </span>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Full name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                css={{
                  borderBottom: '1px solid #E2E2E2',
                  borderColor: '#E2E2E2'
                }}
                alt="Full name"
                role="textbox"
              />
              <Label
                htmlFor="email"
                css={{
                  paddingTop: 30,
                  paddingBottom: 10,
                  '@media all and (max-width: 620px)': {
                    paddingTop: 10
                  }
                }}
              >
                Email
                <span
                  style={{
                    color: 'red'
                  }}
                >
*
                </span>
              </Label>
              <Input
                id="email"
                name="email"
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
                placeholder="yourname@email.com"
                css={{
                  borderBottom: '1px solid #E2E2E2',
                  borderColor: '#E2E2E2'
                }}
                alt="Email"
                role="textbox"
              />
            </Box>
          </Box>
          <Box>
            <Label
              htmlFor="comment"
              css={{
                paddingTop: 20,
                paddingBottom: 10,
                '@media all and (max-width: 620px)': {
                  paddingTop: 10
                }
              }}
            >
              Comment
              <span
                style={{
                  color: 'red'
                }}
              >
*
              </span>
            </Label>
            <Textarea
              id="comment"
              name="comment"
              role="textbox"
              css={{
                height: '120px',
                resize: 'none',
                borderBottom: '1px solid #E2E2E2',
                borderColor: '#E2E2E2',
                '@media all and (max-width: 620px)': {
                  height: '75px'
                }
              }}
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </Box>
          <Box
            mb="30px"
          >
            <Label
              htmlFor="phoneInput"
              css={{
                paddingTop: 20,
                paddingBottom: 10
              }}
            >
              Phone Number
              <span
                style={{
                  color: 'red'
                }}
              >
                *
              </span>
            </Label>
            <PhoneInput
              name="phoneInput"
              onChange={setPhoneNumber}
              isPhoneNumberValid={setIsPhoneNumberValid}
              phone={setPhoneNumber}
            />
          </Box>
          <Box
            alignSelf="center"
            mb="30px"
            style={{
              overflow: 'hidden'
            }}
          >
            {<Recaptcha
              onRecaptchaVerify={setIsRecaptchaValid}
            />}
          </Box>
          <SocialButton
            onClick={onSubmitContactForm}
            backgroundColor = {firstName && userEmail && isPhoneNumberValid && isRecaptchaValid  ? colors.primaryButtonColor : '#585858'}
            aria-disabled= {firstName && userEmail && isPhoneNumberValid && isRecaptchaValid ? false : true}
            aria-label='Submit'
            role='button'
          >
            <Text
              fontFamily="sfPro"
              ref={(ref)=>(elementRefs.current.submit = ref)}
            >
              Submit
            </Text>
          </SocialButton>
        </div>
      ) : <span>{status}</span>
      }
    </Flex>
  );
};
export default ContactUs;
