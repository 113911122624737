/**
 * Fix viewport units on mobile for IOS browser
 * please @see{https://css-tricks.com/the-trick-to-viewport-units-on-mobile/}
 */

// eslint-disable-next-line no-undef
let vh = window.innerHeight * 0.01;
// eslint-disable-next-line no-undef
document.documentElement.style.setProperty('--vh', `${vh}px`);

// eslint-disable-next-line no-undef
window.addEventListener('resize', () => {
  // eslint-disable-next-line no-undef
  vh = window.innerHeight * 0.01;
  // eslint-disable-next-line no-undef
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
