/* eslint-disable no-underscore-dangle */
import { useEffect, useState, useContext } from 'react';
import uniqid from 'uniqid';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import trim from 'lodash/trim';
import { GlobalStateContext } from 'context/global-context';
import { setPageIndex, defaultZoomUp, setPlaceCoords } from 'actions';
const parseBooleanFromString = (value) => {
  if (isString(value)) {
    const truthyValues = ['t', '1', 'y', 'true'];
    return truthyValues.includes(value.toLowerCase());
  }

  return Boolean(value);
};
const mapFromApi = markers => markers.map(marker => ({
  id: marker.LocationID || uniqid(),
  name: `${marker.InstitutionName} ${marker.RetailOutlet}`,
  address: `${marker.Street}, ${marker.City} ${marker.State} ${marker.ZipCode} `,
  icon: marker.ImageURL,
  milesAway: (marker.Distance).toFixed(2),
  lat: marker.Latitude,
  lng: marker.Longitude,
  color: '#04944C',
  phone: marker.WorkPhone,
  websiteUrl: marker.WebsiteUrl,
  isBranch: !!marker.ServiceCenter,
  workingHours: [
    { day: 'Sunday', dayIndex: 0, time: marker.Sunday || 'N/A' },
    { day: 'Monday', dayIndex: 1, time: marker.Monday || 'N/A' },
    { day: 'Tuesday', dayIndex: 2, time: marker.Tuesday || 'N/A' },
    { day: 'Wednesday', dayIndex: 3, time: marker.Wednesday || 'N/A' },
    { day: 'Thursday', dayIndex: 4, time: marker.Thursday || 'N/A' },
    { day: 'Friday', dayIndex: 5, time: marker.Friday || 'N/A' },
    { day: 'Saturday', dayIndex: 6, time: marker.Saturday || 'N/A' }
  ],
  EmailID: marker.EmailID,
  AdditnlDirDtls: !isEmpty(trim(marker.AdditnlDirDtls)) ? marker.AdditnlDirDtls : null,
  CUSpecific: !isEmpty(trim(marker.CUSpecific)) ? marker.CUSpecific : null,
  AccessHoursRestriction: parseBooleanFromString(marker.AccessHoursRestriction),
  RestrictedAccess: parseBooleanFromString(marker.RestrictedAccess),
  LocationID: marker.LocationID,
  DriveUpHrs: [
    { day: 'Sunday', dayIndex: 0, time: marker.DriveUpHrsSun || 'N/A' },
    { day: 'Monday', dayIndex: 1, time: marker.DriveUpHrsMon || 'N/A' },
    { day: 'Tuesday', dayIndex: 2, time: marker.DriveUpHrsTues || 'N/A' },
    { day: 'Wednesday', dayIndex: 3, time: marker.DriveUpHrsWed || 'N/A' },
    { day: 'Thursday', dayIndex: 4, time: marker.DriveUpHrsThurs || 'N/A' },
    { day: 'Friday', dayIndex: 5, time: marker.DriveUpHrsFri || 'N/A' },
    { day: 'Saturday', dayIndex: 6, time: marker.DriveUpHrsSat || 'N/A' }
  ],
}));

const useDataApi = (initialUrl, {
  latitude, longitude, NETWORK_ID, LOCATION_RADIUS, geolocationDefined, initialPageIndex
}) => {
  const [data, setData] = useState([]);
  const [countData, setCountData] = useState({});
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [previousAppliedFilters, setPreviousAppliedFilters] = useState(null);
  const [previousPageIndex, setPreviousPageIndex] = useState(null);
  const [sortByProperty, setSortByProperty] = useState(null);
  const { state, dispatch } = useContext(GlobalStateContext);
  const { state: { isCallSearchApi,callSortApi,currentSearchAddress,searchAddressFromGeoCoder } } = useContext(GlobalStateContext);
  const {
    currentUserCoords,
    appliedFilters,
    siteConfig: { isSiteConfigLoaded },
    pageIndex,
    sortBy,
    isSearchThisAreaClicked
  } = state;
  const { lat, lng } = currentUserCoords;
  
  const [currentCoordinates, setCurrentCoordinates] = useState(null);
  const [isSearchButtonOrAutoCompleteClicked, setIsSearchButtonOrAutoCompleteClicked]=useState(false);

  let actualLatitude = null;
  let actualLongitude = null;
  let searchByOptions = '';
  let pageIndexValue = initialPageIndex;
  let sortOrderType = '';

  if (lat && lng) {
    // user updated map center coordinates
    actualLongitude = lng;
    actualLatitude = lat;
  } else {
    // user current position coordinates
    actualLongitude = longitude;
    actualLatitude = latitude;
  }

  if (pageIndex) {
    pageIndexValue = pageIndex;
  }

  if (appliedFilters) {
    searchByOptions = appliedFilters.map(({ value }) => value).join(', ');
  }

  const shouldFetchLocations = (newLat, newLng) => {
    return true
  };

  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);
    if (actualLatitude && actualLongitude && NETWORK_ID && LOCATION_RADIUS) {
      try {
        dispatch(setPlaceCoords({ lat:actualLatitude, lng:actualLongitude }));
        
        const reqData = {
          NetworkId: NETWORK_ID,
          Latitude: actualLatitude,
          Longitude: actualLongitude,
          Miles: LOCATION_RADIUS,
          SearchByOptions: searchByOptions,
          PageIndex: pageIndexValue
        };

        if(sortBy === 'Alphabetical') {
          reqData['SortBy'] = "InstitutionName";
        }

        const options = {
          method: 'POST',
          body: JSON.stringify(reqData),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        };

        // eslint-disable-next-line no-undef
        const response = await fetch(url, options);
        const json = await response.json();
        const results = json.data.ATMInfo? mapFromApi(json.data.ATMInfo) : [];
        const countData = {TotalRecCount: json.data.TotalRecCount, PageSize: json.data.PageSize, PageIndex: json.data.PageIndex }
        setData(results);
        setCountData(countData)
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    }
  };

  function hasQueryParams(url) {
    return url.indexOf('?') !== -1;
  }

  useEffect(() => {
    // eslint-disable-next-line max-len
    const CAN_LOAD_DATA = geolocationDefined && isSiteConfigLoaded && LOCATION_RADIUS && NETWORK_ID && actualLatitude && actualLongitude && initialPageIndex
      && shouldFetchLocations(parseFloat(actualLatitude), parseFloat(actualLongitude));

    if (CAN_LOAD_DATA) {
      setCurrentCoordinates({
        curLat: parseFloat(actualLatitude),
        curLng: parseFloat(actualLongitude)
      });
      setPreviousAppliedFilters(searchByOptions);
      pageIndexValue = initialPageIndex;
      setPreviousPageIndex(initialPageIndex);
      dispatch(setPageIndex(parseInt(initialPageIndex)));
      if(!hasQueryParams(window.location.href))
      {
        fetchData();
      }
    }
  }, [url, NETWORK_ID, LOCATION_RADIUS, initialPageIndex]);

  useEffect(() => {
    if (previousAppliedFilters !== appliedFilters) {
      pageIndexValue = initialPageIndex;
      dispatch(setPageIndex(parseInt(initialPageIndex)));
      dispatch(defaultZoomUp());
      setPreviousAppliedFilters(searchByOptions);
      fetchData();
    }
  }, [searchByOptions]);

  useEffect(() => {
    if(callSortApi){
    pageIndexValue = 1;
    setPreviousPageIndex(pageIndexValue);
    fetchData();
    }
  }, [sortBy])

  useEffect(() => {
    setIsSearchButtonOrAutoCompleteClicked(true);
    setCurrentCoordinates({
        curLat: parseFloat(actualLatitude),
        curLng: parseFloat(actualLongitude)
      });
      setPreviousAppliedFilters(searchByOptions);
      pageIndexValue = initialPageIndex;
      setPreviousPageIndex(initialPageIndex);
      dispatch(setPageIndex(parseInt(initialPageIndex)));
      /* Checking if current search address in search bar is same as previous address to call API, 
         to prevent multiple api calls on location change*/
      if (currentSearchAddress === searchAddressFromGeoCoder) 
      {
        fetchData();
      }
  }, [isCallSearchApi]);

  useEffect(() => {
    if (previousPageIndex !== pageIndex) {
      setPreviousPageIndex(pageIndex);
      fetchData();
    }
  }, [state]);

  useEffect(() => {
    pageIndexValue = 1;
    setPreviousPageIndex(pageIndexValue);
    if(!hasQueryParams(window.location.href))
      {
        fetchData();
      }
      if(isSearchThisAreaClicked && hasQueryParams(window.location.href))
      {
        fetchData();
      }
      if(isSearchButtonOrAutoCompleteClicked && hasQueryParams(window.location.href))
      { 
        fetchData();
        setIsSearchButtonOrAutoCompleteClicked(false);
      }
  }, [actualLatitude,actualLongitude]);

  return [{ data, countData, isLoading, isError }, setUrl];
};

export default useDataApi;
