// Breakpoints
const breakpoints = [1150, 1024, 768, 425, 375, 320, 430];

// Types scale
const fontSizes = {
  small: '14px',
  regular: '16px',
  medium: '18px',
  large: '20px'
};

// Color palette
const colors = {
  primaryColor: '#256057',
  blue: '#1070E5',
  green: '#256057',
  greenWithOpacity: 'rgba(37, 96, 87, 0.1)',
  black: '#000',
  white: '#fff',
  greyLight: 'rgba(226,226,226,0.25)',
  grey: ['#585858', '#E2E2E2', '#777']
};

// Spaces(possible values of paddings and margins)
const space = {
  tiny: '5px',
  small: '10px',
  medium: '20px',
  mediumX: '25px',
  large: '30px',
  huge: '50px'
};

const fonts = {
  noto: 'Noto Sans',
  roboto: 'Roboto',
  sfPro: 'SF Pro Display'
};

const fontWeights = {
  normal: 'normal',
  bold: 'bold'
};

const lineHeights = ['19px', '21px', '22px', '27px'];

const letterSpacings = ['normal', '0.01em', '0.02em'];

const shadows = {
  light: '0px 10px 20px rgba(0, 0, 0, 0.1)',
  dark: '0px 10px 20px rgba(0, 0, 0, 0.25)'
};

const radii = [4, 8, 31, 38, 100];

const zIndices = [0, 1];

const widths = {
  blockWidth: 375,
  searchBlockWidth: 630,
  resultBlockWidth:850
};

const heights = {
  blockHeight: 80,
  expandedBlockHeight: 700
};

const outline = 'rgb(59, 153, 252) solid 2px';
const outlineOffset = '2px';

const theme = {
  breakpoints,
  fontSizes,
  colors,
  space,
  fonts,
  fontWeights,
  lineHeights,
  letterSpacings,
  shadows,
  radii,
  zIndices,
  widths,
  heights,
  outline,
  outlineOffset,
  focused: {
    outline,
    outlineOffset
  },
  buttons: {
    primary: {
      fontFamily: fonts.sfPro,
      fontWeight: fontWeights.bold,
      fontSize: fontSizes.large,
      lineHeight: lineHeights[3],
      padding: `11.5px ${space.medium}px`,
      cursor: 'pointer',
      color: colors.white,
      backgroundColor: colors.primaryButtonColor,
      borderRadius: radii[1]
    },
    small: {
      fontFamily: fonts.sfPro,
      fontWeight: fontWeights.normal,
      fontSize: fontSizes.small,
      lineHeight: lineHeights[0],
      padding: '0.5px 13px',
      cursor: 'pointer',
      color: colors.white,
      backgroundColor: colors.green,
      borderRadius: radii[2]
    },
    filter: {
      fontFamily: fonts.sfPro,
      fontWeight: fontWeights.bold,
      fontSize: fontSizes.regular,
      lineHeight: '30px',
      padding: '5px 8px 5px 8px',
      cursor: 'pointer',
      color: colors.white,
      backgroundColor: colors.green,
      borderRadius: radii[3],
      height: '40px'
    },
    search: {
      height: '40px',
      widths: '90px',
      padding: '8px 12px',
      borderRadius: 5,
      backgroundColor: colors.green,
      color: colors.white,
      fontFamily: fonts.sfPro,
      fontSize: fontSizes.large,
      cursor: 'pointer'
    },
    direction: {
      padding: '0',
      backgroundColor: 'unset',
      fontFamily: fonts.sfPro,
      fontWeight: fontWeights.normal,
      fontSize: fontSizes.small,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    tabActive: {
      fontSize: fontSizes.regular,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights[2],
      backgroundColor: colors.white,
      color: colors.black,
      cursor: 'pointer',
      borderRadius: 0,
      padding: 0,
      marginLeft: '3px'
    },
    tabNonActive: {
      fontSize: fontSizes.regular,
      fontWeight: fontWeights.normal,
      lineHeight: lineHeights[2],
      backgroundColor: colors.white,
      color: colors.grey[0],
      cursor: 'pointer',
      borderRadius: 0,
      padding: 0,
      marginLeft: '3px'
    },
    apply: {
      width: '100%',
      height: '50px',
      backgroundColor: colors.green,
      borderRadius: radii[1],
      color: colors.white,
      fontSize: '16px',
      fontWeights: fontWeights.bold,
      cursor: 'pointer'
    },
    disabled: {
      width: '335px',
      height: '50px',
      backgroundColor: colors.grey[0],
      borderRadius: radii[1],
      color: colors.white,
      fontSizes: fontSizes.large,
      fontWeights: fontWeights.bold,
      '@media all and (max-width: 620px)': {
        width: '100%'
      }
    },
    transparent: {
      padding: `${space.medium} ${space.huge}`,
      cursor: 'pointer',
      margin: `${space.medium} 0`,
      fontSize: fontSizes.regular,
      border: `2px solid ${colors.black}`,
      borderRadius: '5px',
      color: colors.black,
      background: 'rgba(255,255,255,0)',
      ':hover': {
        color: 'rgba(0,0,0,0.5)'
      },
      ':focus': {
        outline: 'none'
      }
    }
  }
};

export default theme;
