import React from 'react';
import { Box } from 'rebass';
import 'styled-components/macro';

import theme from 'styles/theme';
import withFocus from '../../shared/withFocus';
import withRef from '../../shared/withRef';

const StyledInput = ({ name,isChecked, forwardedRef }) => (
  <Box
    ref={forwardedRef}
    as="input"
    type="checkbox"
    name="search-filter"
    aria-checked={isChecked}
    aria-label={name}
    css={{
      cursor: 'pointer',
      opacity: 0,
      position: 'absolute',
      height: '22px',
      width: '22px',
      marginBottom: 0,
      '&:focus + label::before': {
        outline: theme.outline,
        outlineOffset: theme.outlineOffset
      }
    }}
  />
);

const Checkbox = props => (
  <StyledInput {...props} />
);

export const CheckboxWithFocus = withFocus(withRef(Checkbox));

export default Checkbox;

