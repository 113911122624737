import React, { useContext } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { GlobalStateContext } from 'context/global-context';
import {
  Card
} from 'rebass';

const SearchDetails = () => {
  const { state } = useContext(GlobalStateContext);
  const { atmInfo, siteConfig } = state;

  const info = atmInfo && atmInfo.map((atm, index) => ` ${index ? '|' : ''} ${atm.Miles} miles: ${atm.AtmCount}`);
  const primaryColor = get(siteConfig, ['RgbColor'], '#174B12');

  const AtmInfoWidget = styled.div`
    padding: 10px;
    border: 1px solid ${primaryColor};

    @media only screen and (max-width: 1149px) {
      position: absolute;
      margin-top: 8px;
      text-align: right;
      right: 10px;
    }

    @media only screen and (max-width: 880px) {
      margin-top: 8px;
      text-align: center;
      top: 55px;
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      font-size: 12px;
      left: 0;
      width: 100%;
    }
  `;

  return (
    <Card
      width={[1, 'fit-content']}
      boxShadow="none"
      borderRadius={['0px', 0]}
      backgroundColor="white"
    >
      {
        info ? (
          // eslint-disable-next-line react/jsx-one-expression-per-line
          <AtmInfoWidget>Locations within: {info}</AtmInfoWidget>
        ) : null
      }
    </Card>
  );
};

export default SearchDetails;
