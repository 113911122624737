import React from 'react';
import {
  Text, Flex, Image
} from 'rebass';
import styled from 'styled-components';

import LocationSocials from '../LocationDetails/LocationSocials';

const HeaderBox = styled.div`
  padding: 10px 20px 10px 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  @media only screen and (max-width: 880px) {
    padding: 10px 20px 10px 15px;
  }
`;

const Header = ({ brandName, src, socials }) => (
  <HeaderBox>
    <Flex alignItems="center">
      <Image
        src={src}
        width="auto"
        height="34px"
        alt="Bank brand image"
      />

      {
        brandName ? (
          <Text
            as="h1"
            margin="0 10px 0"
            fontFamily="sfPro"
            fontWeight="bold"
            fontSize="medium"
            style={{
              position: 'relative',
              backgroundColor: 'white'
            }}
          >
            { brandName }
          </Text>
        ) : null
      }

      <LocationSocials socials={socials} />
    </Flex>
  </HeaderBox>
);

Header.defaultProps = {
  brandName: '',
  src: '',
  socials: []
};

export default Header;
