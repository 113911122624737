import React, { useContext } from 'react';
import 'styled-components/macro';
import { Flex } from 'rebass';
import { ThemeContext } from 'styled-components';
import get from 'lodash/get';

import theme from 'styles/theme';
import { ReactComponent as PlusIcon } from 'assets/images/icons/mapControls/plus-icon.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/mapControls/minus-icon.svg';
import { ReactComponent as YourLocationIcon } from 'assets/images/icons/mapControls/your-location-icon.svg';
import { ReactComponent as roadmapIcon } from 'assets/images/icons/mapControls/road-map-icon.svg';
import { ReactComponent as satelliteIcon } from 'assets/images/icons/mapControls/satellite-icon.svg';

import withFocus from '../../../shared/withFocus';
import withRef from '../../../shared/withRef';
import ReactTooltip from 'react-tooltip';
import isMobileDevice from '../../../../../utils/deviceDetector';
const CONTROL_BUTTON_TYPES = {
  PLUS: 'Plus',
  MINUS: 'Minus',
  YOUR_LOCATION: 'Your location',
  MAP_TYPE : 'Map type'//newly added for satelliteview
};



const ControlButton = (props) => {
  const colors = get(useContext(ThemeContext), 'colors', {});

  const {
    type, onClick, onKeyDown, forwardedRef,toolTip,alt,roadmapType
  } = props;

  const BUTTONS_META_MAPPER = {
    [CONTROL_BUTTON_TYPES.PLUS]: {
      borderRadius: `${theme.radii[1]}px ${theme.radii[1]}px 0 0`,
      ariaLabel: 'Zoom in',
      label: 'Zoom in',
      iconComponent: PlusIcon,
      arialabelledby:"Zoom-in-button"
    },
    [CONTROL_BUTTON_TYPES.MINUS]: {
      borderRadius: `0 0 ${theme.radii[1]}px ${theme.radii[1]}px`,
      ariaLabel: 'Zoom out',
      label: 'Zoom out',
      iconComponent: MinusIcon,
      arialabelledby:"Zoom-out-button"
    },
    [CONTROL_BUTTON_TYPES.YOUR_LOCATION]: {
      borderRadius: `${theme.radii[1]}px`,
      ariaLabel: 'Your Location',
      label: 'Your Location',
      iconComponent: YourLocationIcon,
      arialabelledby:"location-button"
    },
    [CONTROL_BUTTON_TYPES.MAP_TYPE]: {
      borderRadius: `${theme.radii[1]}px`,
      ariaLabel: roadmapType ? "Roadmap View" : "Satellite View",
      label: 'Map type',
      iconComponent: roadmapType ? roadmapIcon : satelliteIcon
    }
  };

  const {
    borderRadius,
    ariaLabel,
    iconComponent,
    arialabelledby
  } = BUTTONS_META_MAPPER[type] || {};

  const iconSquareSide = CONTROL_BUTTON_TYPES.YOUR_LOCATION === type ? '22px' : '16px';
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const controlStyles = {
    fill:  colors.primaryButtonColor,
    stroke: roadmapType ? CONTROL_BUTTON_TYPES.MAP_TYPE === type ?  colors.primaryButtonColor : '' :  CONTROL_BUTTON_TYPES.MAP_TYPE === type ?  colors.primaryButtonColor : '',
    width: CONTROL_BUTTON_TYPES.MAP_TYPE === type ? '50px' : iconSquareSide,
    height: CONTROL_BUTTON_TYPES.MAP_TYPE === type ? '50px'  : iconSquareSide
  };

  return (
    <div  data-tip={toolTip}  data-delay-show='300' data-for={arialabelledby} data-effect = 'solid' data-place = 'right'>
    <Flex 
      onClick={handleClick}
      onKeyDown={onKeyDown}
      aria-label={ariaLabel || 'control button'}
      as="button"
      justifyContent="center"
      alignItems="center"
      ref={forwardedRef}
      css={{
        width: '40px',
        height: '40px',
        borderRadius,
        cursor: 'pointer',
        border: 'none',
        backgroundColor: colors.white,
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        padding: '8px',
        [`@media all and (max-height: ${theme.breakpoints[3]}px)`]: {        
          width: !isMobileDevice() ? '30px':'40px',
          height: !isMobileDevice() ? '30px':'40px',
        }
      }}
    >
      {React.createElement(iconComponent, controlStyles)}
    </Flex>
    <ReactTooltip id={arialabelledby}  disable={isMobile} className ="tooltip"/>
    </div>
  );
};

ControlButton.types = {
  PLUS: CONTROL_BUTTON_TYPES.PLUS,
  MINUS: CONTROL_BUTTON_TYPES.MINUS,
  YOUR_LOCATION: CONTROL_BUTTON_TYPES.YOUR_LOCATION,
  MAP_TYPE:CONTROL_BUTTON_TYPES.MAP_TYPE
};

export const ControlButtonWithFocus = withFocus(withRef(ControlButton));

export default ControlButton;
