export const DEFAULT_ZOOM = 12;
export const MAX_ZOOM_LEVEL = 16;

export const INCREASE_ZOOM_LEVEL = 1;
export const DECREASE_ZOOM_LEVEL = 1;

export const DEFAULT_MILESAWAY = 3;

export const DEFAULT_COORDS = {
  LAT: 32.763175,
  LNG: -96.796987
};

export const AUTOCOMPLETE_SUGGESTION_RADIUS = 160934; // 100miles

export const LOCATION_TYPES = {
  BRANCH: 'branch',
  ATM: 'ATM'
};

export const MAIN_PAGE_VIEW = '/';
export const DEFAULT_SORT_OPTION = 'Nearest first';
