import React, { useRef, useEffect } from 'react';

const withFocus = Component => (props) => {
  const focusRef = useRef(null);

  const handleKeyDown = () => focusRef.current.focus();

  const handlePointerDown = (e) => {
    e.preventDefault();
    focusRef.current.blur();

    // eslint-disable-next-line no-undef
    document.activeElement.blur();

    return false;
  };

  useEffect(() => {
    const node = focusRef.current;

    if (node) {
      node.addEventListener('keydown', handleKeyDown, true);
      node.addEventListener('mousedown', handlePointerDown, true);
      node.addEventListener('pointerdown', handlePointerDown, true);
    }

    return () => {
      if (node) {
        node.removeEventListener('keydown', handleKeyDown, true);
        node.removeEventListener('mousedown', handlePointerDown, true);
        node.removeEventListener('pointerdown', handlePointerDown, true);
      }
    };
  }, []);

  return (
    <Component ref={focusRef} {...props} />
  );
};

export default withFocus;
