import React, { useContext, useState, useEffect, useRef, forwardRef } from 'react';
import { Box, Card, Flex, Image } from 'rebass';
import get from 'lodash/get';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import 'styled-components/macro';
import { ThemeContext } from 'styled-components';

import { sendSearchButtonEvent, sendSearchTerm } from 'services/analytics';
import theme from 'styles/theme';
import { setSortBy,isCallSortApi,fetchSerachedLocation,setPlaceCoords, setATMCountsData, toogleFiltersModal, toggleErrorModal, setCurrentUserCoords, defaultZoomUp, hideLocationDetails,setSearchAddressFromGeoCoder } from 'actions';
import { GlobalStateContext } from 'context/global-context';
import {
  Autocomplete, Button, Filters, Icon, ModalTemplate
} from '..';
import withRef from '../../shared/withRef';

import FILTER_ICON from 'assets/images/icons/filter-icon.svg';
import SEARCH_ICON from 'assets/images/icons/SearchIcon.png';
import ReactTooltip from 'react-tooltip';

export const MOCKED_FACETED_NAVIGATION = [];

const Search = ({
  onPlaceSubmit,
  searchData,
  isPlaceEntered,
  networkId,
  isAtmCountsAvailable
}) => {
  const { state, dispatch } = useContext(GlobalStateContext);
  const filterButtonRef = useRef(null);
  const colors = get(useContext(ThemeContext), 'colors', {});
  const [searchValue, setSearchValue] = useState({ address: '' });
  const toggleFilters = () => dispatch(toogleFiltersModal());
  const openErroModal = (errorMessage) => dispatch(toggleErrorModal(errorMessage));

  // eslint-disable-next-line no-undef
  const { IS_FILTERS_FEATURE_ENABLED, NETWORK_ID } = window.CRD_GLOBAL;
  const { isFiltersAvailable } = state;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchValue({ address: searchData });
      navigateToPlace(searchData);
    }, 50);
    return () => clearTimeout(timeout);
  }, [searchData]);

  
  const FilterButton = withRef((props, ref) => (
    <Button forwardRef={ref} {...props}>
      {props.children}
    </Button>
  ))

  useEffect(() => {
    if(!state.isFiltersOpen && filterButtonRef.current) {
      filterButtonRef.current.focus();
    }
  }, [state.isFiltersOpen])

  const navigateToPlace = async (address) => {
    if (!address) return;
    dispatch(hideLocationDetails());
    try {
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);

      if (!isPlaceEntered) {
        onPlaceSubmit();
      }

      if (lat && lng) {
        const params = {
          NetworkID: networkId,
          Latitude: lat,
          Longitude: lng
        };

        if (isAtmCountsAvailable) {
          const options = {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          };

          // eslint-disable-next-line no-undef
          const response = await fetch(GET_ATM_COUNTS_DATA, options);
          const json = await response.json();
          if (json.data) {
            dispatch(setATMCountsData(json.data));
          }
        }
        dispatch(isCallSortApi(false))
        dispatch(setSortBy('Nearest first'));
        dispatch(setPlaceCoords({ lat, lng }));
        dispatch(setCurrentUserCoords({ lat, lng }));
        //setting address after getting location from geocoder 
        dispatch(setSearchAddressFromGeoCoder(address));
        dispatch(defaultZoomUp());
      }
    } catch (e) {
      // eslint-disable-next-line no--console
      console.log(`Error from Google Maps API: ${e}`);
      setSearchValue({ address: '' });
      const errorMessage = { errorTitle: "Location Not Found", errorMessage: "Please Enter a Valid Address Or Zip Code" }
      openErroModal(errorMessage);
    }
  };

  const handleSelect = (address) => {
    dispatch(fetchSerachedLocation(address));
    setSearchValue({ address });
    sendSearchTerm(address, NETWORK_ID);

    navigateToPlace(address);
  };

  const handleChange = (address) => {
    setSearchValue({ address });
  };

  const onSearch = (e) => {
    e.preventDefault();

    if (searchValue.address) {
      /**fetch location api when click on search */
      dispatch(fetchSerachedLocation(searchValue.address));
      sendSearchTerm(searchValue.address, NETWORK_ID);
    }
    
    navigateToPlace(searchValue.address);
  };

  return (
    <Flex
      role="search"
      width="100%"
      mr={[0, 'small']}
      mt={['small', 0]}
      alignSelf="flex-end"
      css={{
        flexBasis: '100%',
        position: 'relative',
        [`@media all and (min-width: ${theme.breakpoints[0]}px)`]: {
          maxWidth: '700px'
        }
      }}
    >
      <Box
        css={{
          position: 'absolute',
          bottom: 92,
          right: 0,
          visibility: state.isFiltersOpen ? 'visible' : 'hidden'
        }}
      >
        {
          IS_FILTERS_FEATURE_ENABLED
            ? (
              <ModalTemplate
                id="search-filters-modal"
                label="Search Filters"
                onClose={toggleFilters}
                role="region"
                aria-labelledby="search-filters-modal"
                open={state.isFiltersOpen}
                cssClass="visibleHeaderforDetails"
              >
                <Filters
                  facetedNavigation={MOCKED_FACETED_NAVIGATION}
                  networkId={networkId}
                />
              </ModalTemplate>
            )
            : null
        }
      </Box>
      <Card
        backgroundColor="white"
        boxShadow="light"
        borderRadius={0}
        width={1}
        pt="medium"
        px={['small', 'medium']}
        css={{
          height: theme.heights.blockHeight
        }}
      >
        <Flex justifyContent="flex-start">
          <Box
            flex="1"
          >
            <Autocomplete
              onSelect={handleSelect}
              onChange={handleChange}
              searchValue={searchValue}
              predictCoords={state.placeCoords}
            />
          </Box>

          <Box
            ml="small"
            mr="small"
          >
              <Button
                styles={{
                  [`@media all and (max-width: ${theme.breakpoints[0]}px)`]: {
                    display: 'none'
                  },
                  position: 'relative'
                }}
                variant="search"
                onClick={onSearch}
                aria-label="Search"
              >
                Search
            </Button>
            <Button
                styles={{
                  [`@media all and (max-width: ${theme.breakpoints[0]}px)`]: {
                    display: 'block !important',
                    borderRadius: '15px'
                  },
                  display: 'none',
                  position: 'relative'
                }}
                variant="search"
                onClick={onSearch}
                aria-label="Search"
                type="image"
              >
                <Image
                  src={SEARCH_ICON}
                  alt="Search input icon"
                  height="100%"
                  aria-hidden="true"
                />
            </Button>
          </Box>
          {
            IS_FILTERS_FEATURE_ENABLED && isFiltersAvailable
              ? (
                <div data-tip='View Search filters' data-for='filter-tip' data-delay-show='300' data-effect='solid' alt='view filter'>
                  <FilterButton
                    id="search-filters-button"
                    ref={filterButtonRef} 
                    variant="filter"
                    styles={{
                      width: '70px',
                      zIndex: '1'
                    }}
                    onClick={toggleFilters}
                    aria-label="Search filters"
                    aria-expanded={state.isFiltersOpen}
                    aria-controls="search-filters-modal"
                  >
                    <Icon
                      src={FILTER_ICON}
                      label="filter"
                      height="20px"
                      width="20px"
                      styles={{
                        float: 'left',
                        marginLeft: '5px',
                        marginTop: '2px'
                      }}
                    />

                    <Card
                      borderRadius="50%"
                      bg="white"
                      color={colors.primaryButtonColor}
                      width="24px"
                      ml="tiny"
                      css={{
                        float: 'left',
                        height: '24px',
                        lineHeight: '24px'
                      }}
                    >
                      {state.appliedFilters.length}
                    </Card>
                  </FilterButton>
                  <ReactTooltip id='filter-tip' disable={isMobile} className="tooltip" />
                </div>

              )
              : null
          }
        </Flex>
        <Box />
      </Card>
    </Flex>
  );
};

Search.defaultProps = {};

export default Search;
