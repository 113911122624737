import React, {useRef} from 'react';
import useFontScaling from "../../../../hooks/useFontScaling";

const CheckboxCheckedIcon = (
  { color }
) => {
  const COLOR_GREEN = '#16934F';
  const elementRefs = useRef({});
  useFontScaling(elementRefs);
  return (
    <>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-label="Checked" ref={(ref) => (elementRefs.current.check = ref)}>
        <path fill={color || COLOR_GREEN} d="M448 0h-384c-35.2 0-64 28.8-64 64v384c0 35.2 28.8 64 64 64h384c35.2 0 64-28.8 64-64v-384c0-35.2-28.8-64-64-64zM224 397.255l-118.627-118.628 45.255-45.254 73.372 73.372 153.373-153.373 45.254 45.255-198.627 198.628z" />
      </svg>
    </>
  );
};

export default CheckboxCheckedIcon;
