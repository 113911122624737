import ReactGA from 'react-ga4';
import {
  ACTIONS,
  CATEGORIES
} from '../constants/analytics';

// eslint-disable-next-line no-undef
const { APP_GOOGLE_ANALYTICS_DIMENSION } = window.CRD_GLOBAL;

const {
  BRANCH,
  ATM,
  SEND_TO_PHONE,
  SEND_TO_EMAIL,
  CONSTACT_US,
  SUBMIT_SEARCH_BUTTON
} = ACTIONS;

const {
  LOCATION,
  SEARCH
} = CATEGORIES;

const sendEvent = (category, action, label = '') => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label
    });
  }
};

// SEARCH
export const sendSearchTerm = (searchTerm, NETWORK_ID) => {
  ReactGA.ga('send', 'pageview', `/?q=${searchTerm}`,{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID} ); 
};

// FORM
export const sendSmsToPhoneOnLoadEvent = (NETWORK_ID) => {
  ReactGA.ga('send', 'event', SEND_TO_PHONE, 'click','','',{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID});
};
/** track email sent */
export const sendSmsToPEmailOnLoadEvent = (NETWORK_ID) => {
  ReactGA.ga('send', 'event', SEND_TO_EMAIL, 'click','','',{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID});
};

export const contactUsOnLoadEvent = (NETWORK_ID) => {
  ReactGA.ga('send', 'event', CONSTACT_US, 'click','','',{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID});
};

export const sendSmsToPhoneSubmitEvent = (NETWORK_ID) => {
  ReactGA.ga('send', 'event', SEND_TO_PHONE, 'submit','','',{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID} );
};

export const sendToEmailOnSubmitEvent = (NETWORK_ID) => {
  ReactGA.ga('send', 'event', SEND_TO_EMAIL, 'submit','','',{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID});
};

export const contactUsSubmitEvent = (NETWORK_ID) => {
  ReactGA.ga('send', 'event', CONSTACT_US, 'submit','','',{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID});
};

// LOCATION TYPE
export const sendLocationTypeBranchEvent = (label, networkId) => {
  sendEvent(LOCATION, BRANCH, label);
  ReactGA.set({ 'ga:networkId': networkId });
};

export const sendLocationTypeAtmEvent = (label, networkId) => {
  sendEvent(LOCATION, ATM, label);
  ReactGA.set({ 'ga:networkId': networkId });
};

// FILTER
export const sendFilterEvent = (filters, NETWORK_ID) => {
  ReactGA.ga('send', 'event', `${filters}`, 'selected filters','','',{[APP_GOOGLE_ANALYTICS_DIMENSION]:NETWORK_ID});
};
