import React, { useReducer, useEffect } from 'react';
import appReducer from 'reducers';
import { setSiteConfig } from 'actions';
import { DEFAULT_ZOOM, DEFAULT_SORT_OPTION } from '../constants/general';

// eslint-disable-next-line no-undef
const APPLICATION_DOMAIN = window.location.hostname;

// TODO Resolve domain
const appDomain = {
  DomainName: APPLICATION_DOMAIN
};

const initialState = {
  isFiltersAvailable: false,
  isLocationInfoOpen: false,
  isResultListModalOpen: false,
  isCallSearchApi:false,
  isModalOpen: false,
  isEmailOpen:false,
  isFiltersOpen: false,
  isSmsSent: false,
  location: {},
  mapCenter: {},
  placeCoords: null,
  resultList: {},
  currentUserCoords: {},
  userCoords: {},
  zoom: DEFAULT_ZOOM,
  appliedFilters: [],
  sortBy: DEFAULT_SORT_OPTION,
  isSelected: '',
  siteConfig: {},
  isSearchThisAreaClicked : false,
  currentSearchAddress:'',
  searchAddressFromGeoCoder:'',
  isStaticPlaceCoords: true
};

const GlobalStateContext = React.createContext(initialState);

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    const fetchAppConfig = async () => {
      try {
        const options = {
          method: 'POST',
          body: JSON.stringify(appDomain),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        };

        // eslint-disable-next-line no-undef
        const response = await fetch(SITE_CONFIG_API_URL, options);
        const { data,statusCode } = await response.json();
        const isValid = statusCode == 200 ? true : false         
        dispatch(setSiteConfig({ ...data, isSiteConfigLoaded: true,isValidSiteConfig:isValid}));
       
      } catch (err) {
        console.log(err);
        dispatch(setSiteConfig({isValidSiteConfig:false}));
      }
    };
    fetchAppConfig();
  }, []);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateContext, GlobalStateProvider };
