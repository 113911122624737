import React, { memo, useContext, useRef } from 'react';
import { Flex, Box } from 'rebass';
import { GlobalStateContext } from 'context/global-context';
import { isCallSortApi,setPageIndex, setSortBy, defaultZoomUp, hideLocationDetails } from 'actions';
import { setUserManaualZoom } from 'actions';
import RadioButtonItem from '../RadioButtonItem/RadioButtonItem';
import useFontScaling from "../../../../../hooks/useFontScaling";

const SortFilters = memo(({ sortBy }) => {
  const { state, dispatch } = useContext(GlobalStateContext);

  const onSelectHandler = value => (e) => {
    e.preventDefault();
    dispatch(setPageIndex(parseInt(1)));
    dispatch(isCallSortApi(true))
    dispatch(setSortBy(value));
    dispatch(setUserManaualZoom(12));
    dispatch(hideLocationDetails());
  };

  const RADIO_BUTTON_GROUP_NAME = 'sorting-group';

  const elementRefs= useRef({});

  useFontScaling(elementRefs);

  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      width="100%"
      py="small"
      px="medium"
      backgroundColor="greyLight"
      lineHeight="17px"
      mb="mediumX"
      marginBottom={5}
    >
      <Box
        as="fieldset"
        role="group"
        style={{
          border: 'none',
          display: 'contents'
        }}
      >
        <Box
          as="legend"
          style={{
            fontSize: 'small',
            lineHeight: '17px',
            userSelect: 'none'
          }}
          ref={(ref)=>(elementRefs.current.sorting = ref)}
        >
        Sorting
        </Box>
        {
          sortBy.map(({ label }) => (
            <Box
              onClick={onSelectHandler(label)}
              key={label}
              ml="medium"
            >
              <RadioButtonItem
                handleChange={onSelectHandler(label)}
                isChecked={state.sortBy === label}
                key={label}
                label={label}
                id={label}
                name={RADIO_BUTTON_GROUP_NAME}
              />
            </Box>
          ))
        }
      </Box>
    </Flex>
  );
});

SortFilters.defaultProps = {
  sortParams: []
};

export default SortFilters;
