import React, { useState, useContext, useRef, useEffect, Fragment } from 'react';
import {
  Flex, Box, Text
} from 'rebass';
import 'react-phone-number-input/style.css';
import get from 'lodash/get';
import useFontScaling from "../../../../../hooks/useFontScaling";
import { ThemeContext } from 'styled-components';
import Icon from '../../Icon/Icon';
import { ReactComponent as SmallMarkerIcon } from 'assets/images/icons/markers/marker-small-icon.svg';

import styled from 'styled-components';


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
/** custom style for ClosedInfoBox */
const ClosedInfoBox = styled.div`
  background: #FFDC61;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  padding: 2px 5px;
  margin-left: 5px;
  text-align: center
`;

const PrintableList = ({ item, index }) => {
  // eslint-disable-next-line no-unused-vars
  const elementRefs = useRef({});

  useFontScaling(elementRefs);
  const colors = get(useContext(ThemeContext), 'colors', {});
  const commonStyles = {
    flexShrink: 0,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '100%'
  };

  /** This function return Atm openstatus message depends om each staus */
  const getClosedInfoMessage = (restrictedAccess, accessHoursRestriction) => {
    let locationClosedMessage = restrictedAccess ? 'Restricted Access' : null;
    if (accessHoursRestriction) {
      if (restrictedAccess)
        locationClosedMessage = 'Restricted Access/Limited Hours';
      else
        locationClosedMessage = 'Limited Hours';
    }
    return locationClosedMessage;
  };


  return (
    <>
      <li
        style={{
          height: 'auto',
          margin: '1rem'
        }}>
        <Flex
          mb="small"
        >
          <Box
            style={{
              ...commonStyles,
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              borderRadius: '50%'
            }}
          >
            <Icon
              src={item.icon}
              label={item.name}
              height="40px"
              width="40px"
              isBordered
            />
          </Box>
          <Box
            ml="5px"
            width={[1, 240]}
            mr={[40, 0]}
          >
            <Text
              fontWeight="bold"
              fontSize="regular"
              lineHeight={2}
              mb="5px"
              ref={(ref) => (elementRefs.current.name = ref)}
            >
              {item.name}
            </Text>
            <Text
              color="#585858"
              fontSize="small"
              lineHeight={0}
              ref={(ref) => (elementRefs.current.address = ref)}
            >
              {item.address}
            </Text>
          </Box>
        </Flex>
        <Flex>
          <SmallMarkerIcon
            fill={colors.primaryButtonColor}
          />
          <Text
            fontWeight="bold"
            fontSize="13px"
            lineHeight={0}
            color={get(colors, ['grey', 0])}
            ml="tiny"
            ref={(ref) => (elementRefs.current.milesAway = ref)}
          >
            {`${item.milesAway} Miles away`}

          </Text>
        </Flex>
        {
          getClosedInfoMessage(item.RestrictedAccess, item.AccessHoursRestriction) ?
            <Fragment>
              <Flex
                alignItems="center"
                mt="5px"
                justifyContent="space-between"
              >
                <Flex>
                  <ClosedInfoBox ref={(ref) => (elementRefs.current.closedInfoMessage = ref)}>
                    {getClosedInfoMessage(item.RestrictedAccess, item.AccessHoursRestriction)}
                  </ClosedInfoBox>
                </Flex>
              </Flex>
            </Fragment>

            : null
        }
      </li>
    </>
  );
};

export default PrintableList;
