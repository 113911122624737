// takes the Error  message and title as input and show it in modal,
// Genric modal can be used to show error message accross the application 
import React, { useContext } from 'react';
import { GlobalStateContext } from 'context/global-context';
import ReactModal from 'react-modal';
import theme from 'styles/theme';
import { toggleErrorModal, togglePhoneModal, isSendPhoneError,toggleEmailModal,isSendEmailError } from 'actions';
import isMobileDevice from '../../../../utils/deviceDetector';
import {
  ModalTemplate
} from '..';

const ErrorModal = () => {
  const { state: { isErrorModalOpen, errorData, showBackSmsModal,showBackEmailModal }, dispatch } = useContext(GlobalStateContext);
  const toggleModalWindow = () => {
    dispatch(toggleErrorModal());
    if(showBackSmsModal) {
      dispatch(togglePhoneModal());
      dispatch(isSendPhoneError(false))
    }
    if(showBackEmailModal)
    {
      dispatch(toggleEmailModal());
      dispatch(isSendEmailError(false))
    }
  };
  let errorMessage = '';
  let errorTitle = ''
  if(errorData) {
    errorMessage = errorData.errorMessage;
    errorTitle = errorData.errorTitle;
  }
  const onAfterOpen = () => {
    // eslint-disable-next-line no-undef
  };

  return (
    <ReactModal
      isOpen={isErrorModalOpen}
      onRequestClose={toggleModalWindow}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#313131cc',
          zIndex: 2
        },
        content: {
          position: 'initial',
          padding: 'none',
          border: 'none',
          borderRadius: theme.radii[1],
          overflow: 'hidden'
        }
      }}
      contentLabel="Error Modal"
    >
    {errorData && <ModalTemplate
        label={errorTitle}
        onClose={toggleModalWindow}
        cssClass='visibleCenterModal'
      >
          <div>{errorMessage}</div>
      </ModalTemplate>}
    </ReactModal>
  );
};


ErrorModal.defaultProps = {
    errorData: {},
    isErrorModalOpen: false
  }
export default ErrorModal;
