import React, { useState } from 'react';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import { Flex } from 'rebass';
import Tab from './Tab';
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const Tabs = (props) => {
  const { children } = props;

  const initialTab = children.length && children[0].props.label;
  const [activeTab, setActiveTab] = useState(initialTab);

  const onTabItemClick = tab => setActiveTab(tab);
  const isActiveTabAvailable = some(children, child => child.props.label === activeTab);
  const availableActiveTab = isActiveTabAvailable ? activeTab : initialTab;
  /**handling scrolll bar sizes  */
  const isAdjustHeight = () => {
    if(isMobile){
      return false
    }
    else{
      return !isEmpty(children) ? children[0].props.isAdjustHeight : "";
    }
  }
  return (
    <>
      {
        !isEmpty(children) ? (
          <Flex
            role="tablist"
            flexWrap="wrap"
            flexDirection="row"
          >
            <Flex
              width="100%"
            >
              {
                children.map(({ props: { label } }) => (
                  <Tab
                    key={label}
                    activeTab={availableActiveTab}
                    label={label}
                    onClick={onTabItemClick}
                    role="tab"
                  />
                ))
              }
            </Flex>
            <Flex
              width="100%"
              fontSize="small"
              tabindex="0"
              role="tabpanel"
              flexDirection="column"
              style={isAdjustHeight()? {
                height: '220px',
                overflow: 'auto',
              }:{
                height: 'auto',
                overflow: 'auto',
              }}
              tabIndex="0"
            >
              {
                children.map(({ props: childProps }) => {
                  if (childProps.label !== availableActiveTab) return null;

                  return childProps.children;
                })
              }
            </Flex>
          </Flex>
        ) : null
      }
    </>
  );
};

export default Tabs;
