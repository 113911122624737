import actions from 'constants/actionTypes';

const {
  SHOW_LOCATION_DETAILS,
  HIDE_LOCATION_DETAILS,
  SET_FOCUSED_LOCATION,
  TOGGLE_FILTERS_MODAL,
  TOGGLE_PHONE_MODAL,
  FETCH_SEARCH_LOCATION,
  TOGGLE_EMAIL_MODAL,
  TOGGLE_SMS_MODAL_ERROR,
  CALL_SORT_API,
  TOGGLE_EMAIL_MODAL_ERROR,
  TOGGLE_RESULT_LIST_MODAL,
  TOGGLE_CONTACT_US_MODAL,
  TOGGLE_ERROR_MODAL,
  FIND_MY_LOCATION,
  SET_PLACE_COORDS,
  SET_CURRENT_USER_COORDS,
  SET_APPLIED_FILTERS,
  SET_PAGE_INDEX,
  SET_SORT_BY,
  MAP_LOCATION_ZOOM,
  MANUAL_ZOOM_LEVEL,
  DEFAULT_ZOOM_UP,
  CLOSE_MODALS,
  TOGGLE_SMS_MODAL,
  SET_ATM_COUNTS_DATA,
  SET_SITE_CONFIG,
  SET_FILTERS_AVAILABILITY,
  SET_SEARCH_THIS_AREA_CLICKED,
  SET_SEARCHADDRESS_FROM_GEOCODER,
  SET_STATIC_PLACE_COORDS
} = actions;

const parseCoordsToFloat = ({ lat, lng }) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng)
});

export const showLocationDetails = (coords, location) => ({
  type: SHOW_LOCATION_DETAILS,
  payload: {
    mapCenter: parseCoordsToFloat(coords),
    location
  }
});

export const setFocusedLocation = id => ({
  type: SET_FOCUSED_LOCATION,
  payload: {
    id
  }
});

export const hideLocationDetails = () => ({
  type: HIDE_LOCATION_DETAILS
});

//function to se the default zoom on the map
export const defaultZoomUp = () => ({
  type: DEFAULT_ZOOM_UP
});

export const isSentSmsToggle = () => ({
  type: TOGGLE_SMS_MODAL
});

// setting flag to show send phone modal
export const isSendPhoneError = (showBackSmsModal) => ({
  type: TOGGLE_SMS_MODAL_ERROR,
  payload: {
    showBackSmsModal: showBackSmsModal
  }
});
/** soert rest */
export const isCallSortApi = (callSortApi) => ({
  type: CALL_SORT_API,
  payload: {
    callSortApi: callSortApi
  }
});

// setting flag to show email modal
export const isSendEmailError = (showBackEmailModal) => ({
  type: TOGGLE_EMAIL_MODAL_ERROR,
  payload: {
    showBackEmailModal: showBackEmailModal
  }
});

export const togglePhoneModal = () => ({
  type: TOGGLE_PHONE_MODAL
});
/** search model */
export const fetchSerachedLocation = (currentSearchAddress) => ({
  type: FETCH_SEARCH_LOCATION,
  payload:{
    currentSearchAddress:currentSearchAddress
  }
});
/** email model */
export const toggleEmailModal = () => ({
  type: TOGGLE_EMAIL_MODAL
});
export const toggleResultListModal = (listData) => ({
  type: TOGGLE_RESULT_LIST_MODAL,
  payload: {
    listData
  }
});

export const toggleContactUsModal = () => ({
  type: TOGGLE_CONTACT_US_MODAL
});

export const toggleErrorModal = (errorData) => ({
  type: TOGGLE_ERROR_MODAL,
  payload: {
    errorData
  }
});

export const toogleFiltersModal = () => ({
  type: TOGGLE_FILTERS_MODAL
});

export const updateMapPosition = coords => ({
  type: FIND_MY_LOCATION,
  payload: {
    mapCenter: parseCoordsToFloat(coords)
  }
});

export const setUserCurrentLocation = coords => ({
  type: FIND_MY_LOCATION,
  payload: {
    currentUserCoords: coords
  }
});

// setting cords on search and on update from map
export const setMapLocationWithZoom = coords => ({
  type: MAP_LOCATION_ZOOM,
  payload: {
    userCoords: coords
  }
});

// setting manual zoom on map
export const setUserManaualZoom = zoomLevel => ({
  type: MANUAL_ZOOM_LEVEL,
  payload: {
    zoomLevel: zoomLevel
  }
});

export const setPlaceCoords = coords => ({
  type: SET_PLACE_COORDS,
  payload: {
    placeCoords: coords,
    mapCenter: parseCoordsToFloat(coords)
  }
});

export const setATMCountsData = data => ({
  type: SET_ATM_COUNTS_DATA,
  payload: {
    atmInfo: data
  }
});

export const setCurrentUserCoords = coords => ({
  type: SET_CURRENT_USER_COORDS,
  payload: {
    currentUserCoords: coords
  }
});

export const setAppliedFilters = appliedFilters => ({
  type: SET_APPLIED_FILTERS,
  payload: {
    appliedFilters
  }
});

// setting pageindex to access during api call
export const setPageIndex = pageIndex => ({
  type: SET_PAGE_INDEX,
  payload: {
    pageIndex
  }
})

export const setSortBy = sortBy => ({
  type: SET_SORT_BY,
  payload: {
    sortBy
  }
});

export const closeModals = () => ({
  type: CLOSE_MODALS
});

export const setSiteConfig = siteConfig => ({
  type: SET_SITE_CONFIG,
  payload: siteConfig
});

export const setFiltersAvailability = isFiltersAvailable => ({
  type: SET_FILTERS_AVAILABILITY,
  payload: {
    isFiltersAvailable
  }
});

export const setSearchThisAreaClicked = isSearchThisAreaClicked => ({
  type: SET_SEARCH_THIS_AREA_CLICKED,
  payload: {
    isSearchThisAreaClicked:isSearchThisAreaClicked
  }
});

export const setSearchAddressFromGeoCoder = address => ({
  type: SET_SEARCHADDRESS_FROM_GEOCODER,
  payload: {
    searchAddressFromGeoCoder: address
  }
});

/**To set static place coords*/
export const setStaticPlaceCoords = isStaticPlaceCoords => ({
  type: SET_STATIC_PLACE_COORDS,
  payload: {
    isStaticPlaceCoords:isStaticPlaceCoords
  }
});
