import React, {useRef} from 'react';
import { Box, Text } from 'rebass';
import map from 'lodash/map';
import get from 'lodash/get';
import tzlookup from 'tz-lookup';
import isString from 'lodash/isString';
import LocationTimeInfo from '../LocationTimeInfo';
import { isNull,isUndefined } from 'lodash';
import useFontScaling from "../../../../../hooks/useFontScaling";

const AM_PM_REGEX = /[Aa][Mm]|[Pp][Mm]/;
const HH_MM_REGEX = /^\d{1,2}:\d{2}$/;

const convertTime12to24 = (time12h = '') => {
  const preparedTime12h = time12h.split(' ').join('');
  const [time] = preparedTime12h.split(AM_PM_REGEX);
  const modifier = preparedTime12h.includes('am') ? 'AM' : 'PM';

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }

  return `${hours}:${minutes}`;
};

/** This function handles to check whether is valid time format */
const checkTimeisvalid = (time12h = '') => {
  const preparedTime12h = time12h.split(' ').join('');
  const [time] = preparedTime12h.split(AM_PM_REGEX);
  const modifier = preparedTime12h.includes('am') ? 'AM' : 'PM';
  var [hours, minutes] = time.split(':');
  return minutes
}

const LocationWorkingHours = ({ days = [], lat, lng,branchTab }) => {
  const currentDate = new Date();
  const currentDayIndex =  currentDate.getDay();
  let isOpened = true;
  /** removing hours from location closed message */
  branchTab = branchTab.replace("Hours", "");
  if (lat && lng) {
    const timeZone = tzlookup(lat, lng);
    const currentTimeLocaleString = currentDate.toLocaleString(
      'en-US',
      {
        timeZone
      }
    );

    const timeInfo = get(days.find(day => day.dayIndex === currentDayIndex), 'time', '').toLowerCase();
    const isclosed = (timeInfo ===  "closed") || (timeInfo ===  "n/a") ? true :false;
    var time = get(days.find(day => day.dayIndex === currentDayIndex), 'time', '').toLowerCase();
    time = time.replace(/\./g,'')
    const [from, to] = time.split('-');
    const fromHours = from && from.replace(/\s/g, '');
    const toHours = to && to.replace(/\s/g, '');
    const HH_MM_MATCH = fromHours.match(HH_MM_REGEX) && toHours.match(HH_MM_REGEX);
    var AM_PM_MATCH =""
    if(!isUndefined(toHours)){
      AM_PM_MATCH = fromHours.match(AM_PM_REGEX) && toHours.match(AM_PM_REGEX);
    }
    if(isclosed){
      isOpened = false;
    }
    else if(isNull(AM_PM_MATCH)){
      isOpened = true;
    }
    else{
    if (isString(from) && isString(to) && (HH_MM_MATCH || AM_PM_MATCH)) {
      const todayDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
      const tomorrowDate = `${currentDate.getMonth() + 1}/${currentDate.getDate() + 1}/${currentDate.getFullYear()}`;

      const from12 = !fromHours.match(AM_PM_REGEX) ? `${fromHours}am` : fromHours;
      const to12 = !toHours.match(AM_PM_REGEX) ? `${toHours}pm` : toHours;

      const from24 = convertTime12to24(from12);
      const to24 = convertTime12to24(to12);
      const fromDate = new Date(`${todayDate} ${from24}`).getTime();
      const toDate = new Date(`${from24 > to24 ? tomorrowDate : todayDate} ${to24}`).getTime();
      const currentLocaleTime = new Date(currentTimeLocaleString).getTime();
      if(isUndefined(checkTimeisvalid(from12)) || isUndefined(checkTimeisvalid(to12))){
        isOpened = true
      }
      else{
       isOpened = fromDate < currentLocaleTime && currentLocaleTime < toDate;
      }
    }
    }
  }
  const checkTime = (time) =>  time === "N/A" ? 'Closed' : time;
  const elementRefs= useRef({});
  const locationTimeInfoRefs= useRef({});

  useFontScaling(elementRefs); 
  return (
    <>
      {
        !isOpened
          ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '4px 0 0 0'
              }}
              ref={(ref)=>(elementRefs.current.branchTab = ref)}
            >
              {branchTab} is
              <span
                style={{
                  display: 'inline-block',
                  background: '#B30000',
                  padding: '4px 7px',
                  borderRadius: '11px',
                  marginLeft: '4px',
                  color: '#ffffff'
                }}
              >
                CLOSED
              </span>
            </div>
          )
          : null
      }
      {
        map((days), (day, index) => {
          const isOdd = index % 2;
          const { day: dayName, time } = day;
          return (
            <Box
              key={dayName}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                backgroundColor: isOdd ? '#fafafa' : 'inherit',
                lineHeight: isOdd ? '24px' : '30px',
                border: !isOpened && currentDayIndex === day.dayIndex ? '1px solid #c5c5c5' : 'none',
                borderLeft: 'none',
                borderRight: 'none'
              }}
            >
              <Text
                style={{
                  color: '#585858',
                  width: '100px'
                }}
                ref={(ref)=>(elementRefs.current[index] = ref)}
              >
                {dayName}
              </Text>
              <LocationTimeInfo time={checkTime(time)} ref={locationTimeInfoRefs} />
            </Box>
          );
        })
      }
    </>
  );
};
export default LocationWorkingHours;
