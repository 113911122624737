import React, { useContext } from 'react';
import { GlobalStateContext } from 'context/global-context';
import ReactModal from 'react-modal';
import theme from 'styles/theme';
import { toggleContactUsModal, isSentSmsToggle } from 'actions';
import isMobileDevice from '../../../../utils/deviceDetector';
import {
  ModalTemplate,
  ContactUs
} from '..';
import { contactUsOnLoadEvent } from '../../../../services/analytics';

const ContactUsModal = () => {
  const { state: { isContactUsModalOpen, isSmsSent }, dispatch } = useContext(GlobalStateContext);
  const toggleModalWindow = () => {
    dispatch(toggleContactUsModal());
    if (isSmsSent) dispatch(isSentSmsToggle()); // TODO
  };

  const onAfterOpen = () => {
    // eslint-disable-next-line no-undef
    const { NETWORK_ID } = window.CRD_GLOBAL;
    contactUsOnLoadEvent(NETWORK_ID);
  };

  return (
    <ReactModal
      isOpen={isContactUsModalOpen}
      onRequestClose={toggleModalWindow}
      onAfterOpen={onAfterOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isMobileDevice() ? 'none' : '#313131cc',
          zIndex: 2,
          position: 'fixed',
          top: 0,
          left:0,
          width:'100%',
          height:'100%',
          overflow: 'auto',
        },
        content: {
          position: 'initial',
          padding: 0,
          border: 'none',
          borderRadius: theme.radii[1],
          overflowX: 'hidden',
          overflowY: 'auto',
          maxHeight: '100vh',
        }
      }}
      contentLabel="Contact Us Modal"
    >
      <ModalTemplate
        label="Contact Us"
        onClose={toggleModalWindow}
      >
        <ContactUs />
      </ModalTemplate>
    </ReactModal>
  );
};

export default ContactUsModal;
