import React from 'react';
import { Box } from 'rebass';
import 'styled-components/macro';

import theme from 'styles/theme';
import withFocus from '../../../shared/withFocus';
import withRef from '../../../shared/withRef';

const StyledRadio = ({
  name, label,isChecked, handleChange, forwardedRef
}) => {
  const onClick = (e) => {
    e.preventDefault();

    if (handleChange) handleChange(e);
  };

  return (
    <Box
      id={label}
      ref={forwardedRef}
      as="input"
      type="radio"
      name={name}
      value={name}
      aria-checked={isChecked}
      onChange={onClick}
      css={{
        cursor: 'pointer',
        opacity: 0,
        position: 'absolute',
        height: '22px',
        width: '22px',
        '&:focus + label::before': {
          outline: theme.outline,
          outlineOffset: theme.outlineOffset
        }
      }}
    />
  );
};

const RadioButton = props => (
  <StyledRadio {...props} />
);

export default withFocus(withRef(RadioButton));

