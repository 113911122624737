import React, { useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import { Flex, Text, Link } from 'rebass';
import get from 'lodash/get';
import 'styled-components/macro';
import { ReactComponent as PhoneIcon } from 'assets/images/icons/phone.svg';
import useFontScaling from "../../../../../hooks/useFontScaling";

const LocationPhone = ({ phone }) => {
  const colors = get(useContext(ThemeContext), 'colors', {});
  const elementRefs= useRef({});

  useFontScaling(elementRefs);
  return (
    <Flex
      alignItems="center"
      mt="10px"
    >
      <PhoneIcon fill={colors.primaryButtonColor} />
      <Text
        fontSize="small"
        lineHeight={0}
        color="black"
        ml="5px"
        ref={(ref)=>(elementRefs.current.phone = ref)}
      >
        <Link
          href={`tel:${phone}`}
          aria-label = {`Call phone number ${phone}`}
          css={{
            color: '#585858',
            textDecoration: 'underline dashed'
          }}
        >
          {phone}
        </Link>
      </Text>
    </Flex>
  );
};

LocationPhone.defaultProps = {
  phone: '+235989012'
};

export default LocationPhone;

