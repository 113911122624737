import React, { memo } from 'react';
import { Image } from 'rebass';
import 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const Marker = memo(({ imgSrc, alt, styles,tip }) => (
  <div data-tip= 'Search Location'  data-delay-show='300' data-for={tip} data-background-color = 'black'>
  <Image
    src={imgSrc}
    alt={alt}
    css={{
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      ...styles
    }}
  />
    <ReactTooltip id={tip}  disable={isMobile} className ="tooltip"/>
   </div>
));

export default Marker;
