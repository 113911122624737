import React from 'react';
import ARROW_ICON from 'assets/images/icons/arrows/arrow-right.svg';

import { Icon } from '..';
import { Button } from 'rebass';

const Arrow = ({ onClick, name, address }) => {
  const handleKeyPress=(e)=>{
    if (e.key==="Enter") {
      onClick();
    }
  }
  return (
  <Button
    width="14px"
    height="14px"
    onClick={onClick}
    onKeyDown={handleKeyPress}
    tabIndex="0"
    role="button"
    aria-label={`Location details [${name}, ${address}]`}
    style={{
      position: 'absolute',
      top: '35%',
      right: '5px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      background: 'transparent'
    }}
  >
    <Icon src={ARROW_ICON} />
    </Button>
  );
  };

export default Arrow;
