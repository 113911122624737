import React, { useEffect, useState, useContext } from 'react';

import get from 'lodash/get';

import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { GlobalStateContext } from 'context/global-context';
import initialTheme from './theme';
import GlobalStyles from './GlobalStyles';

const Theme = (props) => {
  const [theme, setTheme] = useState(initialTheme);
  const { state: { siteConfig } } = useContext(GlobalStateContext);
  const { children } = props;

  useEffect(() => {
    const primaryColor = get(siteConfig, ['RgbColor'], initialTheme.colors.primaryColor);
    const primaryButtonColor = get(siteConfig, ['ButtonColor'], initialTheme.primaryButtonColor) || initialTheme.colors.green;

    const composedTheme = {
      ...initialTheme,
      colors: {
        ...initialTheme.colors,
        primaryColor,
        primaryButtonColor
      }
    };
    setTheme(composedTheme);
  }, [siteConfig]);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Normalize />
        <GlobalStyles />
        {children}
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Theme;
