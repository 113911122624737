import React from 'react';
import { Link } from 'react-router-dom';
import {
  Image,
  Flex,
  Box,
  Button
} from 'rebass';
import 'styled-components/macro';

import IMAGE from 'assets/images/icons/404-page-background.svg';
import LOGO from 'assets/images/robot.png';
import locatorsearch from 'assets/images/locatorsearch.gif';
import theme from 'styles/theme';

const BRAND_NAME = process.env.REACT_APP_BRAND_NAME || '';

const NotFound = () => (
  <Flex
    alignItems="top"
    justifyContent="center"
    css={{
      '&:before': {
        content: 'no-open-quote',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: `url(${IMAGE}) no-repeat center`,
        backgroundSize: 'cover'
      },
      height: '100vh'
    }}
  >
    <Flex
      flexDirection="column"
      mx="medium"
      css={{
        zIndex: '1'
      }}
    >
      
      <Flex
        alignItems="top"
        css={{
          '@media all and (max-width: 720px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
          }
        }}
      >
       
          <Box
            as="h1"
            css={{
              fontSize: '16px',
              margin: 0,
              color:'red',
              paddingTop:'20px'
            }}
          >
           Sorry !!! This site is not setup.
          </Box>
          <Box
        marginBottom="large"
        css={{
          height: '200px'
        }}
      >
        <Image
          src={LOGO}
          alt={`${BRAND_NAME} logo`}
          height="100%"
          borderRadius="5px"
          paddingLeft = "100px"
          paddingTop = "20px"
        />
      </Box>
      
      </Flex>
      <Box
        marginBottom="large"
        css={{
          height: '35px'
        }}
      >
        <Image
          src={locatorsearch}
          alt={`${BRAND_NAME} logo`}
          height="100%"
          borderRadius="5px"
        />
      </Box>
    </Flex>
  </Flex>
);

export default NotFound;
