import React, { useState } from 'react';
import { Box } from 'rebass';
import ARROW_DOWN from 'assets/images/icons/arrows/chevron-down.svg';
import PhoneInputNumber from 'react-phone-number-input';
import Metadata from 'libphonenumber-js/mobile/index';
import styled from 'styled-components';
import 'styled-components/macro';

const BassClass = 'react-phone-number-input';
const { GET_COUNTRY_FLAG } = window.CRD_GLOBAL;
const useStyles ={
  '.PhoneInputCountryIcon': {
    border: 'none'
  },
  '.PhoneInputInput': {
    height: '1.8rem',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    border: 'none',
    'border-bottom': '1px solid #c5d2e0',
    '-webkit-transition': 'border .1s',
    transition: 'border .1s',
    'font-size': 'inherit'
  },
  '.PhoneInputCountry': {
    paddingLeft: '30px'
  },
  '.PhoneInputCountrySelectArrow': {
    position: 'absolute',
    left: '4px',
    width: '15px',
    height: '8px',
    border: 'none',
    transform: 'none',
    margin: 'unset',
    opacity: '1',
    background: `
          url(${ARROW_DOWN}) 
          center / contain
          no-repeat
        `
  }
}

const StyledPhoneInput = styled(PhoneInputNumber)({
  [`& .PhoneInputCountryIcon`]: {
    border: 'none'
  },
  [`& .PhoneInputInput`]: {
    height: '1.8rem',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    border: 'none',
    'border-bottom': '1px solid #c5d2e0',
    '-webkit-transition': 'border .1s',
    transition: 'border .1s',
    'font-size': 'inherit'
  },
  [`& .PhoneInputCountry`]: {
    paddingLeft: '30px'
  },
  [`& .PhoneInputCountrySelectArrow`]: {
    position: 'absolute',
    left: '4px',
    width: '15px',
    height: '8px',
    border: 'none',
    transform: 'none',
    margin: 'unset',
    opacity: '1',
    background: `
          url(${ARROW_DOWN}) 
          center / contain
          no-repeat
        `
  }
});

const PhoneInput = ({ phone, isPhoneNumberValid }) => {
  const countryCode = '+1';
  const [phoneNumber] = useState(countryCode);

  const validatePhoneNumber = (number) => {
    let isValid = false;
    try {
    // eslint-disable-next-line no-useless-escape
      const regex = RegExp('(([(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})');
      isValid = regex.test(number);
    } catch (e) {
      isValid = false;
    }
    if (isValid) {
      isPhoneNumberValid(true);
      return true;
    }
    isPhoneNumberValid(false);
    return false;
  };

  const validateData = (data) => {
    phone(data);
    validatePhoneNumber(data);
  };

  return (
    <Box as="label">
      <StyledPhoneInput
        defaultCountry="US"
        international
        country="US"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={data => validateData(data)}
        flagUrl={GET_COUNTRY_FLAG}
      />
    </Box>
  );
};

export default PhoneInput;
