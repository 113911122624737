import React, { useCallback, useState, useEffect } from 'react';

const withMeasuredHeight = Component => (props) => {
  const { getSize, style, ...others } = props;
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (height && getSize) {
      getSize(height);
    }
  }, [height, getSize]);

  const measuredRef = useCallback((node) => {
    if (node) {
      const nodeHeight = node.getBoundingClientRect().height+33;

      setHeight(nodeHeight);
    }
  }, []);

  return (
    <div style={style}>
      <Component forwardedRef={measuredRef} {...others} />
    </div>
  );
};

export default withMeasuredHeight;
