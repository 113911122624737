import { useEffect, useState } from "react";

const useFontScalingArr= (elementRefs) => {
    const [storedFontScale]=useState(parseInt(localStorage.getItem('fontScale')));

    const [percent]=useState(()=>{
        if (storedFontScale && storedFontScale>100) {      
        return storedFontScale - 100;
        }else if (storedFontScale && storedFontScale<100) {      
        return (100 - storedFontScale);
        }  
    });
    
    useEffect(()=>{
        const scalingFactor=0.2;
        const curPercent=100;

        elementRefs.length>0 && elementRefs.forEach((element)=>{
            if (element) {
                const style= window.getComputedStyle(element);
                const initialFontSize= parseFloat(style.fontSize);

                if (storedFontScale>100) {
                    const delta = (initialFontSize / 100) * (curPercent - 100 + percent) * scalingFactor;
                    element.style.fontSize=`${initialFontSize + delta}px`;
                    element.dataset.initialFontSize =`${initialFontSize}px`;
                  } else if (storedFontScale<100) {
                    const delta = (initialFontSize / 100) * (curPercent - 100 - percent) * scalingFactor;
                    element.style.fontSize=`${initialFontSize + delta}px`;
                    element.dataset.initialFontSize =`${initialFontSize}px`;
                  }
            }
        });
    }, [elementRefs]);
}

export default useFontScalingArr;