import React, { useContext,useEffect } from 'react';
import {
  Flex, Box
} from 'rebass';
import theme from 'styles/theme';
import 'styled-components/macro';
import isNil from 'lodash/isNil';
import isMobileDevice from '../../../../utils/deviceDetector';

import { GlobalStateContext } from 'context/global-context';
import {
  togglePhoneModal,
  toggleEmailModal,
  toggleContactUsModal,
  hideLocationDetails
} from 'actions';

import {
  LocationDetails,
  ModalTemplate,
  LocationDetailsModal
} from '..';
import { replace } from 'lodash';

const LocationInfo = ({hideSearchBtn}) => {
  const { state, dispatch } = useContext(GlobalStateContext);

  const openSendToPhoneModal = () => dispatch(togglePhoneModal());
/** add email model */
  const openSendToEmailModal = () => {
    dispatch(toggleEmailModal());
  }

  const openContactUsModal = () => dispatch(toggleContactUsModal());

  const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

  const viewWebSite = () => {
    const myUrl = withHttp(state.location.websiteUrl);
    window.open(myUrl, "_blank") 
  }

  let largeWindow = false;
  let mediumWindow = false;

  const closeExpandedInfo = () =>{
    hideSearchBtn()
   dispatch(hideLocationDetails());
  }
   if(state.isLocationInfoOpen){
    hideSearchBtn()
   }
  /** This function return if location has valid additional details */
  const checkDetailInfo = () => {
    if(isNil(state.location.AdditnlDirDtls) && state.location.workingHours.every(day => day.time === 'N/A') && state.location.DriveUpHrs.every(day => day.time === 'N/A')){
      return true
    }
    else{
      return isNil(state.location.AdditnlDirDtls)  ?  false : (state.location.workingHours.every(day => day.time === 'N/A') && state.location.DriveUpHrs.every(day => day.time === 'N/A')) ? true : false;  
    }
  };

  // this function check the flag and button in location commenting this will remove once testing is done
  // const CheckButtonDetails = () => {
  //   if (state.location.address.length >= 70 && (state.location.AccessHoursRestriction || state.location.RestrictedAccess) && (state.location.EmailID || state.location.websiteUrl)) {
  //     largeWindow = true;
  //     return true;
  //   }  else if (state.location.address.length >= 70) {
  //     mediumWindow = true;
  //     return true;
  //   } else if((state.location.AccessHoursRestriction || state.location.RestrictedAccess) &&  state.location.EmailID && state.location.websiteUrl) {
  //     return true;
  //   } else if (!(state.location.AccessHoursRestriction || state.location.RestrictedAccess) && (state.location.EmailID || state.location.websiteUrl)) {
  //     mediumWindow = true;
  //     return true;
  //   } else if((state.location.AccessHoursRestriction || state.location.RestrictedAccess) && (state.location.EmailID || state.location.websiteUrl)) {
  //     mediumWindow = true;
  //     return true;
  //   } else if ((state.location.AccessHoursRestriction || state.location.RestrictedAccess) && !(state.location.EmailID || state.location.websiteUrl)) {
  //     mediumWindow = true;
  //     return true;
  //   } else if (!(state.location.AccessHoursRestriction || state.location.RestrictedAccess) && !(state.location.EmailID || state.location.websiteUrl)) {
  //     return false;
  //   }

  // }
  return (

    <Flex
      width={[1, theme.widths.blockWidth]}
      mr={[0, 'small']}
      css={{
        position: 'relative',
        flexShrink: 0,
        
      }}
    >
      <Box
        css={{
          position: 'absolute',
          zIndex: 2,
          width: '100%',
          bottom: 0,
          visibility: state.isLocationInfoOpen ? 'visible' : 'hidden'
        }}
      >
      { state.location.id && <LocationDetailsModal
          id="location-info-modal"
          onClose={closeExpandedInfo}
          label="Location Details"
          role="region"
          aria-label="location-info"
          open={state.isLocationInfoOpen}
          cssClass="visibleHeaderforDetails"
        >
          <Box
            css={{
              height: isMobileDevice() ? '' : checkDetailInfo() ? '' :  'auto',//CheckButtonDetails() ? (largeWindow ? '492px' : (mediumWindow ? '465px' : '482px')) : '420px',
              overflow: 'hidden'
            }}
          >
            <LocationDetails
              location={state.location}
              onSendToPhone={openSendToPhoneModal}
              onContactUs={openContactUsModal}
              onViewWebsite={viewWebSite}
              onSendToEmail={openSendToEmailModal}
            />
          </Box>
        </LocationDetailsModal> 
        }
      </Box>
    </Flex>

  );
};

LocationInfo.defaultProps = {
};

export default LocationInfo;
