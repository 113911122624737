import React, { Fragment, useContext } from 'react';
import { Box, Text } from 'rebass';
import get from 'lodash/get';
import 'styled-components/macro';
import { ThemeContext } from 'styled-components';

import { CheckboxWithFocus, Icon } from '..';
import CHECK_MARK from 'assets/images/icons/arrows/checkmark.svg';

const CheckboxItem = (props) => {
  const colors = get(useContext(ThemeContext), 'colors', {});

  const {
    label, src, withIcon, isChecked
  } = props;

  return (
    <Fragment>
      <CheckboxWithFocus
        id={label}
        name={label}
        isChecked={isChecked ? true : false}
      />
      <Box
        htmlFor={label}
        as="label"
        css={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          '&:before': {
            content: 'no-open-quote',
            display: 'inline-block',
            height: '20px',
            width: '20px',
            borderRadius: '2px',
            border: `2px solid ${colors.primaryButtonColor}`,
            background: isChecked
              ? `url(${CHECK_MARK}) center/15px 11px no-repeat`
              : 'none'
          }
        }}
      >
        {withIcon && (
        <Box
          ml="small"
        >
          {
          src ? (
            <Icon
              width="42px"
              height="42px"
              isRounded
              src={src}
              label={label}
            />
          ) : null
        }
        </Box>
        )}
        <Text
          ml={withIcon ? 'tiny' : 'small'}
          fontSize="16px"
        >
          {label}
        </Text>
      </Box>
    </Fragment>
  );
};

export default CheckboxItem;
