// eslint-disable-next-line no-undef
const { SMS_SERVICE_API_URL } = window.CRD_GLOBAL;
const { EMAIL_SERVICE_API_URL } = window.CRD_GLOBAL;

export function sendSmsToPhone(params) {
  const options = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return fetch(SMS_SERVICE_API_URL, options);
};
/** API handler for SendToEmail */
export function sendToEmail(params) {
  const options = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return fetch(EMAIL_SERVICE_API_URL, options);
};
