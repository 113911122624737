import React, { Fragment, useContext, useRef } from 'react';
import { Box, Text } from 'rebass';
import get from 'lodash/get';
import 'styled-components/macro';
import { ThemeContext } from 'styled-components';

import theme from 'styles/theme';
import RadioButton from '../RadioButton/RadioButton';
import useFontScaling from "../../../../../hooks/useFontScaling";


const RadioButtonItem = (props) => {
  const colors = get(useContext(ThemeContext), 'colors', {});
  const {
    label, id, handleChange, isChecked, name
  } = props;

  const elementRefs= useRef({});

  useFontScaling(elementRefs);

  return (
    <Fragment>
      <RadioButton
        id={id}
        label={label}
        name={name}
        handleChange={handleChange}
        isChecked={isChecked ? true : false}
      />
      <Box
        htmlFor={label}
        as="label"
        css={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          '&:after': {
            content: 'no-open-quote',
            display: 'block',
            height: '10px',
            width: '10px',
            position: 'absolute',
            top: '5px',
            left: '5px',
            borderRadius: '50%',
            background: isChecked
              ? colors.primaryButtonColor
              : 'none'
          },
          '&:before': {
            content: 'no-open-quote',
            display: 'inline-block',
            height: '16px',
            width: '16px',
            borderRadius: '50%',
            border: `2px solid ${colors.primaryButtonColor}`
          }
        }}
      >
        <Text
          ml="tiny"
          color={theme.colors.black}
          fontSize="small"
          css={{
            userSelect: 'none'
          }}
          ref={(ref)=>(elementRefs.current.label = ref)}
        >
          {label}
        </Text>
      </Box>
    </Fragment>
  );
};

export default RadioButtonItem;
