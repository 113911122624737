import React, { useState, useContext, useRef } from 'react';
import {
  Flex, Box, Text
} from 'rebass';
import isEmpty from 'lodash/isEmpty';
import { sendSmsToPhoneSubmitEvent } from 'services/analytics';
import { sendSmsToPhone } from 'services/smsService';
import { isSentSmsToggle, toggleErrorModal, togglePhoneModal, isSendPhoneError } from 'actions';
import 'react-phone-number-input/style.css';
import { GlobalStateContext } from 'context/global-context';
import PhoneInput from './PhoneInput';
import Recaptcha from './Recaptcha';
import  SocialButton  from '../Button/SocialButton';
import get from 'lodash/get';
import { ThemeContext } from 'styled-components';
import useFontScaling from "../../../../hooks/useFontScaling";

const SendPhone = () => {
  // eslint-disable-next-line no-unused-vars
  const { state, state: { isSmsSent, location: { LocationID } }, dispatch } = useContext(GlobalStateContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [status, setFormStatus] = useState('');
  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const colors = get(useContext(ThemeContext), 'colors', {});
  const elementRefs= useRef({});
  const isSentSmsMessageToggle = () => {
    dispatch(isSentSmsToggle());
  };
  const openErroModal = (errorMessage) => { 
    dispatch(togglePhoneModal())
    dispatch(toggleErrorModal(errorMessage)) 
  };

  const onSubmitSmsToPhone = async () => {
    // eslint-disable-next-line no-undef
    const { NETWORK_ID } = window.CRD_GLOBAL;
    let response;
    
    if (!isEmpty(phoneNumber) && !sendButtonClicked) {
      sendSmsToPhoneSubmitEvent(NETWORK_ID);
      setSendButtonClicked(true);
      try {
        response = await sendSmsToPhone({
          UserName: '24F6FD63',
          Password: '24F6FD63-480E-4B0F-B484-41D3BEF9970E',
          Phone: phoneNumber,
          LocationID,
          NetworkID: NETWORK_ID
        });

        if (response.status === 200) {
          const successMessage = {errorTitle: "Send to phone", errorMessage: "Send to phone form has been successfully submitted"}
          dispatch(isSendPhoneError(false))
          openErroModal(successMessage);
        } else {
          const errorMessage = {errorTitle: "Something Went Wrong", errorMessage: "Please enter a valid phone number and try again."}
          dispatch(isSendPhoneError(true))
          openErroModal(errorMessage);
        }

      } catch (err) {
        const errorMessage = {errorTitle: "Something Went Wrong", errorMessage: "Please enter a valid phone number and try again."}
        dispatch(isSendPhoneError(true))
        openErroModal(errorMessage);
      }
    }
  };
  useFontScaling(elementRefs);

  return (
    <fieldset> 
    <legend></legend>
    <Flex
      flexDirection="column"
      css={{
        width: '335px',
        '@media all and (max-width: 620px)': {
          width: '100%'
        }
      }}
    >
      {!isSmsSent ? (
        <div>
          <Text
            fontFamily="sfPro"
            mb="70px"
          >
            Provide your phone number below to receive the coordinates of the location via SMS
          </Text>
          <Box
            mb="50px"
          >
            <PhoneInput
              onChange={setPhoneNumber}
              isPhoneNumberValid={setIsPhoneNumberValid}
              phone={setPhoneNumber}
            />

            <div
              style={{
                padding: '10px 0 0 0'
              }}
            >
              Msg & Data rates may apply
            </div>
          </Box>
          <Box
            alignSelf="center"
            mb="85px"
            style={{
              overflow: 'hidden'
            }}
          >
            {<Recaptcha
              onRecaptchaVerify={setIsRecaptchaValid}
            />}
          </Box>
          <SocialButton
            onClick={isPhoneNumberValid && isRecaptchaValid && !sendButtonClicked ? onSubmitSmsToPhone : ''}
            backgroundColor = {isPhoneNumberValid && isRecaptchaValid && !sendButtonClicked ? colors.primaryButtonColor : '#585858'}  
            aria-disabled= {isPhoneNumberValid && isRecaptchaValid && !sendButtonClicked ? false : true}
            aria-label='Send SMS'
            role='button'
          >
            <Text
              fontFamily="sfPro"
              ref={(ref)=>(elementRefs.current.sendSms = ref)}
            >
              Send SMS
            </Text>
          </SocialButton>
        </div>
      ) : <span>{status}</span>
      }
    </Flex>
    </fieldset>
  );
};

export default SendPhone;
