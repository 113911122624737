import React, { useContext, memo } from 'react';
import BRAND_ICON from 'assets/images/icons/brand-icon-default.svg';
import { GlobalStateContext } from 'context/global-context';
import keyboardCodes from 'constants/keyboardCodes';
import ReactTooltip from 'react-tooltip';
import {
  showLocationDetails,
  hideLocationDetails,
  setStaticPlaceCoords
} from 'actions';

const COLOR_GREEN = '#16934F';
const { ENTER } = keyboardCodes.ALPHANUMERIC.FUNCTIONAL;
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const LocationMarker = memo(({ location, activeLocationId = '', color }) => {
  const {
    id,
    icon,
    lat,
    lng,
    name
  } = location;

  const { state, dispatch } = useContext(GlobalStateContext);

  const openLocationDetailsModal = () => {
    const { expandedLocationInfo, location: currentLocation } = state;

    if (expandedLocationInfo && currentLocation.id === id) {
      dispatch(hideLocationDetails());
      return;
    }

    dispatch(showLocationDetails({ lat, lng }, location));
    dispatch(setStaticPlaceCoords(true));
  };

  const onKeyDownHandler = (e) => {
    const { key } = e;

    e.preventDefault();
    if (key === ENTER) openLocationDetailsModal();
  };

  // TMP DISABLED
  const opacity = (!activeLocationId || activeLocationId === id) ? 1 : 1;

  const markerBoxStyles = {
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
    cursor: 'pointer',
    opacity
  };

  const imageStyles = {
    width: '100%',
    display: 'block'
  };

  const imageBoxStyles = {
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'absolute',
    width: '32px',
    height: '32px',
    top: '2px',
    left: '2px',
    background: '#fff'
  };

  return (
     <div data-tip= {name} data-for= {id.toString()}  data-background-color = 'black' data-effect ='float'> 
    <div
      role="presentation"
      style={markerBoxStyles}
      onClick={openLocationDetailsModal}
      onKeyDown={onKeyDownHandler}
    >
      <div style={imageBoxStyles}>
        <img
          alt=''
          style={imageStyles}
          src={icon}
        />
      </div>
      <svg
        width="36"
        height="44"
        viewBox="0 0 36 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          d="M36 18C36 23.1105 33.8703 27.7235 30.4499 31L18 43.5L5.55006 31C2.12973 27.7235 0 23.1105 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
          fill={color || COLOR_GREEN}
        />
      </svg>
    </div>
    <ReactTooltip id={id.toString()}  disable={isMobile} className ="tooltip"/>
    </div>
  );
});

LocationMarker.defaultProps = {
  logo: BRAND_ICON,
  color: 'green',
  selectedLocation: {}
};

export default LocationMarker;
