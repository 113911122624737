import React from 'react';
import { Flex, Box } from 'rebass';
import 'styled-components/macro';
import CheckboxItem from '../../CheckboxItem/CheckboxItem';

const SearchFilters = (props) => {
  const { facetedNavigation, onChange, selectedFilters } = props;
  const isSelected = label => selectedFilters.find(({ name }) => name === label);

  const onSelectHandler = value => () => {
    onChange(value);
  };

  return (
    <fieldset> 
    <legend></legend>
    <Flex
      flexDirection="column"
      flexWrap="wrap"
      width="335px"
    >
      {
        facetedNavigation.map(item => (
          <Box
            key={item.label}
            mb={20}
            onClick={onSelectHandler(item.label)}
            css={{
              userSelect: 'none'
            }}
          >
            <CheckboxItem
              key={item.label}
              isChecked={isSelected(item.label)}
              withIcon
              {...item}
            />
          </Box>
        ))
      }
    </Flex>
    </fieldset>
  );
};

SearchFilters.defaultProps = {
  facetedNavigation: []
};

export default SearchFilters;
