import React, {useContext,useEffect,useState } from 'react';
import { Route } from 'react-router-dom';
import Home from 'scenes/Home/Scene';
import NotFound from 'scenes/shared/NotFound';
import { GlobalStateContext } from 'context/global-context';
import get from 'lodash/get';
import { isUndefined } from 'lodash';

// export default ProtectedRoute;
const ProtectedRoute = () => {
  const { state } = useContext(GlobalStateContext);
  return isUndefined(state.siteConfig.isValidSiteConfig) || state.siteConfig.isValidSiteConfig ? (
    <Route exact path="/" component={Home}  />
  ) : (
    <Route exact path="/" component={NotFound}  />
  );

  
};
export default ProtectedRoute;