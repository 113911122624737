import React, { useContext, useRef } from 'react';
import { Flex, Text, Button } from 'rebass';
import get from 'lodash/get';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as SmallMarkerIcon } from 'assets/images/icons/markers/marker-small-icon.svg';
import { ReactComponent as DirectionMarkerIcon } from 'assets/images/icons/direction-icon.svg';
import useFontScaling from "../../../../../hooks/useFontScaling";

const DistanceWidget = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 310px;

  @media only screen and (max-width: 640px) {
    justify-content: space-between;
  }
`;

const ClosedInfoBox = styled.div`
  background: #FFDC61;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 2px 5px;
  margin-left: 5px;
  text-align: center
`;

const LocationDistance = ({
  milesAway,
  onClick,
  isResultSearch,
  closedInfo,
  address,
  name
}) => {
  const colors = get(useContext(ThemeContext), 'colors', {});
  const elementRefs= useRef({});

  useFontScaling(elementRefs);
  return (
    <DistanceWidget>
      <Flex>
        <SmallMarkerIcon
          fill={colors.primaryButtonColor}
        />
        <Text
          fontWeight="bold"
          fontSize="13px"
          lineHeight={0}
          color={get(colors, ['grey', 0])}
          ml="tiny"
          ref={(ref)=>(elementRefs.current.milesAway = ref)}
        >
          {`${milesAway} Miles away`}
        </Text>
      </Flex>
      {
        
            <Button
              aria-label={`Get directions ${name} ${address}`}
              variant="direction"
              onClick={onClick}
              tabIndex={isResultSearch ? -1 : 0}
            >
              <DirectionMarkerIcon
                fill={colors.primaryButtonColor}
              />
              <Text
                fontSize="13px"
                lineHeight={0}
                color={colors.primaryButtonColor}
                ml="5px"
                mr="10px"
                ref={(ref)=>(elementRefs.current.directions = ref)}
              >
                Get Directions
              </Text>
            </Button>
          
      }
    </DistanceWidget>
  );
};

export default LocationDistance;
