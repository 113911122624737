import React, { useContext } from 'react';
import { Button as BaseButton } from 'rebass';
import get from 'lodash/get';
import 'styled-components/macro';
import { ThemeContext } from 'styled-components';

import withFocus from '../../shared/withFocus';
import withRef from '../../shared/withRef';

const SocialButton = (props) => {
  //Commom compontent created for handling Social Buttons styles
  const { forwardedRef,backgroundColor, styles = {}, ...others } = props;
  //Button Style with dynamic background color
  const btnStyles = {
    backgroundColor: backgroundColor,
    width: '100%',
    height: '50px',
    borderRadius: 8,
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeights: 'bold',
    cursor: 'pointer'
  };
  return (
    <BaseButton ref={forwardedRef} css={btnStyles} {...others} />
  );
};

export const ButtonWithFocus = withFocus(withRef(SocialButton));

export default SocialButton;
