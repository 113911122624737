import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Box } from 'rebass';

import 'react-phone-number-input/style.css';

// eslint-disable-next-line no-undef
const { RECAPTCHA_SITE_KEY } = window.CRD_GLOBAL;

const Recaptcha = ({ onRecaptchaVerify }) => {
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [expired, setExpired] = useState(false);

  // TODO Implement http post method to verify token
  const verifyRecaptchaToken = () => {
    setTimeout(() => {
      onRecaptchaVerify(true);
    }, 500);
  };

  const handleCaptchaResponseChange = (response) => {
    setToken(response);

    if (response === null) {
      onRecaptchaVerify(false);
    } else {
      verifyRecaptchaToken();
    }
  };

  return (
    <Box>
      <ReCAPTCHA
        onChange={handleCaptchaResponseChange}
        sitekey={RECAPTCHA_SITE_KEY}
      />
    </Box>
  );
};

export default Recaptcha;
