import React from 'react';
import { Box, Card } from 'rebass';
import { SpinLoader } from 'react-css-loaders';
import 'styled-components/macro';
import PlacesAutocomplete from 'react-places-autocomplete';

import { AUTOCOMPLETE_SUGGESTION_RADIUS } from 'constants/general';
import theme from 'styles/theme';
import AutocompleteInput from './AutocompleteInput';

const Autocomplete = ({
  onSelect, onChange, searchValue, predictCoords
}) => {
  const handleChange = address => onChange(address);

  const handleSelect = async (address) => {
    if (address) {
      onSelect(address);
    }
  };

  const handleError = (status, clearSuggestions) => clearSuggestions();

  const searchOptions = {
    // eslint-disable-next-line no-undef
    location: new window.google.maps.LatLng(predictCoords),
    radius: AUTOCOMPLETE_SUGGESTION_RADIUS,
    types: ['geocode']
  };

  return (
    <PlacesAutocomplete
      value={searchValue.address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={handleError}
      searchOptions={searchOptions}
      debounce={250}
    >
      {({
        getInputProps, getSuggestionItemProps, suggestions, loading
      }) => (
        <Box>
          <AutocompleteInput
            inputProps={getInputProps()}
          />
          <Card
            data-initial-font-size="16px"
            id="search-places-listbox"
            role="listbox"
            aria-labelledby="search-places"
            backgroundColor="white"
            boxShadow="light"
            borderRadius={0}
            width={['100%', theme.widths.blockWidth]}
            css={{
              position: 'absolute',
              bottom: 90,
              left: 0,
              zIndex: 1
            }}
          >
            {loading && (
              <Box css={{
                position: 'absolute',
                zIndex: 2,
                minHeight: '100px',
                height: '100%',
                width: '100%',
                bottom: 0,
                left: 0,
                background: 'white',
                display: 'flex'
              }}
              >
                <Box
                  css={{
                    display: 'flex',
                    margin: 'auto',
                    minHeight: '120px'
                  }}
                  alt="Map Loading"
                  aria-label='Map Loading'
                  role="img"
                >
                  <SpinLoader background="white" color={theme.colors.grey[0]} duration="1" size="2" />
                </Box>
              </Box>
            )}
            <span role="option" title = "auto"  aria-selected={false} />
            {!!suggestions.length && (
            <Box
              p="tiny"
              css={{
                position: 'relative'
              }}
            >
              {suggestions.map((suggestion) => {
                const id = suggestion.placeId;
                const style = suggestion.active
                  ? { backgroundColor: theme.colors.greyLight, cursor: 'pointer' }
                  : { backgroundColor: theme.colors.white, cursor: 'pointer' };

                return (
                  <Box
                    role="option"
                    key={id}
                    p="small"
                    {...getSuggestionItemProps(suggestion, { style })}
                    fontFamily="sfPro"
                    css={{
                      cursor: 'pointer',
                      position: 'relative',
                      zIndex: 1
                    }}
                  >
                    <span>{suggestion.description}</span>
                  </Box>
                );
              })}
            </Box>
            )}
          </Card>
        </Box>
      )}
    </PlacesAutocomplete>
  );
};

Autocomplete.defaultProps = {};

export default Autocomplete;
