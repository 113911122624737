import React, {useRef} from 'react';
import { Button, Text } from 'rebass';
import theme from 'styles/theme';
import 'styled-components/macro';
import useFontScaling from "../../../../../hooks/useFontScaling";

const Tab = ({ label, activeTab, onClick }) => {
  const onTabClick = () => {
    onClick(label);
  };

  const isActiveTab = label === activeTab;
  const elementRefs= useRef({});

  useFontScaling(elementRefs);
  return (
    <Button
      role="tab"
      variant={`${isActiveTab ? 'tabActive' : 'tabNonActive'}`}
      onClick={onTabClick}
      aria-selected={isActiveTab}
      css={{
        margin: 0,
        padding: '0 15px 0 15px',
        borderRight: '1px solid #E2E2E2',
        '&:first-child': {
          paddingLeft: '3px'
        },
        '&:last-child': {
          paddingRight: '3px',
          borderRight: 'none'
        }
      }}
    >
      <Text
        css={{
          display: 'inline-block',
          paddingBottom: '5px',
          borderBottom: `2px solid ${
            isActiveTab
              ? theme.colors.green
              : 'transparent'
          }`
        }}
        ref={(ref)=>(elementRefs.current.label = ref)}
      >
        {label}
      </Text>
    </Button>
  );
};

export default Tab;
