import React, { useRef } from 'react';
import CheckboxCheckedIcon from '../CheckboxCheckedIcon/CheckboxCheckedIcon';
import { Text } from 'rebass';
import useFontScaling from "../../../../hooks/useFontScaling";

function ServiceDetails(props) {
    const { item, color } = props;
    const elementRefs = useRef({});
    useFontScaling(elementRefs);

    return (
        <li>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    minHeight: '30px',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    marginBottom: '1px'
                }}
            >
                <div
                    style={{
                        width: '15px',
                        height: '15px',
                        position: 'absolute',
                        top: '50%',
                        left: '0',
                        transform: 'translateY(-50%)'
                    }}
                >
                    <CheckboxCheckedIcon color={color} />
                </div>
                <Text ref={(ref) => (elementRefs.current.text = ref)}
                >{item}</Text>
            </div>
        </li>
    )
}

export default ServiceDetails;
