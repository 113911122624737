import React, {
  useState, useContext, useEffect, useRef, forwardRef
} from 'react';
import {
  Flex, Box, Text, Card, Heading
} from 'rebass';
import {toggleResultListModal, setPageIndex, hideLocationDetails, defaultZoomUp } from 'actions';
import posed from 'react-pose';
import 'styled-components/macro';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoize from 'lodash/memoize';
import curry from 'lodash/curry';

import theme from 'styles/theme';
import { GlobalStateContext } from 'context/global-context';
import { LocationDetailsWithHeight as LocationDetails } from 'scenes/Home/components';
import EXPAND_ARROW_ICON from 'assets/images/icons/arrows/expand-arrow.svg';
import SortFilters from './SortFilters';
import { DEFAULT_SORT_OPTION } from '../../../../constants/general';
import keyboardCodes from '../../../../constants/keyboardCodes';
import isMobileDevice from '../../../../utils/deviceDetector';
import { setFocusedLocation, showLocationDetails } from '../../../../actions';
import { IconWithFocus, Button } from '..';
import ReactTooltip from 'react-tooltip';

const {
  ALPHANUMERIC: { FUNCTIONAL: { ENTER, SPACE } },
  ARROW_PAD: { UP, DOWN }
} = keyboardCodes;

export const MOCKED_SORT_PARAMS = [{
  label: 'Alphabetical'
}, {
  label: 'Nearest first'
}];
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const AnimatedBox = posed.div({
  open: {
    height: 'calc(100vh - 380px)',
    transition: { duration: 200 }
  },
  closed: {
    height: '0px',
    transition: { duration: 200 }
  }
});
/** keyevent  handler for result list */
let arrowKeyPressed = false;
const keyEvent = (e) => {
  if(!arrowKeyPressed && (e.keyCode === 32 || e.keyCode === 13)) { //Space & Enter key
    e.stopPropagation()
    e.preventDefault()
  }
  if(e.keyCode === 40 || e.keyCode === 38) {
    arrowKeyPressed = true;
  }
  
}
const outerElementType = forwardRef((props, ref) => (
  <div ref={ref} tabIndex={0} {...props} onKeyDown={keyEvent} className='listDiv'/> // eslint-disable-line
));

const getSortedResults = (results, sortBy) => {
  if (sortBy === DEFAULT_SORT_OPTION) {
    return [...results].sort((a, b) => (a.milesAway - b.milesAway));
  }

  return [...results].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });
};

const DEFAULT_LOCATION_ITEM_HEIGHT = 100;
const mobSize = window.innerHeight - 400;
const mobSizeSmall = window.innerHeight - 480;

const Results = (props) => {
  const { results, countData } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { state, dispatch } = useContext(GlobalStateContext);
  const [sortedResults, setSortedResults] = useState([]);
  const [previousPageIndex, setPreviousPageIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const listRef = useRef(null);
  const itemSize = {};
  let viewMore = false;
  let viewPrevious = false;
  let {
    pageIndex
  } = state;

  useEffect(() => {
    setSortedResults(getSortedResults(results, state.sortBy));
    setActiveIndex(-1)
  }, [results, state.sortBy]);

  useEffect(() => {
    if (previousPageIndex !== pageIndex) {
      setPreviousPageIndex(pageIndex);
      dispatch(defaultZoomUp());
      setSortedResults([]);
    }
  }, [pageIndex]);
 /**scroll to top when new search happens*/
  useEffect(() => {
    if(listRef.current && pageIndex==1){
      listRef.current.scrollToItem(0)
    }
  }, [results]);

  const togglePanel = () => setIsExpanded(!isExpanded);
  
  const openResultListModal = () => dispatch(toggleResultListModal(results));
  const getItemSize = index => itemSize[index] || DEFAULT_LOCATION_ITEM_HEIGHT;

  const setSize = memoize(curry((index, height) => {
    itemSize[index] = height;
    listRef.current.resetAfterIndex(index);
  }));
  // checks if viewmore existing in object array
  const checkMore = sortedResults.map((data) => {
    return data.id === 'viewmore'
  })
  // checks if view previous existing in object array
  const checkPrevious = sortedResults.map((data) => {
    return data.id === 'viewprevious'
  })
  let calculatedPageIndex = Math.ceil(countData.TotalRecCount/countData.PageSize);
  if (sortedResults.length > 0 && !checkMore.includes(true) && countData.PageIndex < calculatedPageIndex && sortedResults.length >= countData.PageSize) {
    viewMore = true;
    sortedResults.push({id:'viewmore',name:'viewmoreData'})
  }
  if (sortedResults.length > 0 && !checkPrevious.includes(true) && countData.PageIndex > 1) {
    viewPrevious = true;
    sortedResults.unshift({id:'viewprevious',name:'viewpreviousData'})
    if(document.getElementsByClassName('listDiv')[0]) {
      document.getElementsByClassName('listDiv')[0].firstChild.className = 'viewPreviousButton';
    }
  } else if(countData.PageIndex === 1) {
    const containsPrev = sortedResults.map((data) => {
      return data.id === 'viewprevious'
    }).includes(true)
    if (containsPrev) {
      viewPrevious = false;
      sortedResults.shift();
      if(document.getElementsByClassName('listDiv')[0]) {
        document.getElementsByClassName('listDiv')[0].firstChild.className = '';
      }
    }
  }


  const onPanelKeydown = ({ key }) => {
    if ([ENTER, SPACE].includes(key)) {
      togglePanel();
    }
  };

  const onItemKeydown = ({ key }) => {
    if ([ENTER, SPACE].includes(key)) {
      const location = sortedResults[activeIndex] || {};
      if (location.id === 'viewmore' || location.id === 'viewprevious') {
        // button function handled
        if(!pageIndex) {
          pageIndex = 1
        }
        if (location.id === 'viewmore') {
          dispatch(hideLocationDetails());
          dispatch(defaultZoomUp());
          dispatch(setPageIndex(parseInt(pageIndex)+1));
        } else {
          dispatch(hideLocationDetails());
          dispatch(defaultZoomUp());
          dispatch(setPageIndex(parseInt(pageIndex)-1));
        }
      } else {
        if (Object.keys(location).length > 0) {
          dispatch(showLocationDetails({
            lat: location.lat,
            lng: location.lng
          }, location));
        }
      }
      return;
    }

    let scrollTo;

    if ([UP, DOWN].includes(key)) {
      if (key === DOWN) {
        scrollTo = Math.min(activeIndex + 1, results.length);
      } else if (key === UP) {
        scrollTo = Math.max(activeIndex - 1, 0);
      }

      const { id } = sortedResults[scrollTo] || {};

      if (activeIndex !== scrollTo) {
        if (activeIndex !== -1 && activeIndex !== results.length - 1) {
          listRef.current.scrollToItem(scrollTo);
        }

        setActiveIndex(scrollTo);
        dispatch(setFocusedLocation(id));
      }
    }
  };

  const linkStyle = {
    margin: isExpanded ? '0rem 0 0.5rem 0rem' : '0',
    cursor: 'pointer',
    display: isExpanded ? 'inline-block' : ''
  };

  const renderLocationDetails = (locationProps) => {
    const { style, index } = locationProps;
    const location = sortedResults[index];
    const custStyle = {...style}
    if(location && (location.id === 'viewmore' || location.id === 'viewprevious')) {
      custStyle.height = 50;
    }

    return (
      <LocationDetails
        style={custStyle}
        location={location}
        isResultSearch
        pageIndex={countData.PageIndex} 
        getSize={setSize(index)}
      />
    );
  };

  return (
    <Flex
      width={[1, theme.widths.resultBlockWidth]}
      alignSelf="flex-end"
      css={{
        position: 'relative',
        [`@media screen and (min-width: 1500px)`]: {
          width:theme.widths.blockWidth
        },
      }}
    >
      <Card
        backgroundColor="white"
        boxShadow="light"
        borderRadius={0}
        width={1}
        minHeight={theme.heights.blockHeight}
        pb="0px"
        css={{
          position: 'absolute',
          bottom: 0,
          zIndex: 1
        }}
      >
        <Box>
          <Flex
            px="medium"
            justifyContent="space-between"
            alignItems="center"
            onClick={togglePanel}
            css={{
              cursor: 'pointer'
            }}
          >
            <Box
              pt="26px"
              pb="27px"
            >
              <Heading
                lineHeight={3}
                fontFamily="sfPro"
                fontWeight="bold"
                fontSize="large"
                css={{
                  position: 'relative'
                }}
              >
                Results (
                {results && results.length > 0? countData.TotalRecCount < countData.PageSize ? countData.TotalRecCount :  countData.PageSize : 0}
                {results && results.length > 0 && countData.TotalRecCount > countData.PageSize? '+' : ''}
                )
              </Heading>
            </Box>
            <Box pt="26px" pb="mediumX">
              <div  data-delay-show='300'  data-tip= {isExpanded ? ' Collapse Result Window': 'Expand Result Window'}  data-for='controllButton-tip' data-effect = 'solid'>
              <IconWithFocus
                tabIndex="0"
                styles={{
                  cursor: 'pointer',
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
                }}
                onKeyDown={onPanelKeydown}
                width="24px"
                height="24px"
                src={EXPAND_ARROW_ICON}
                label="Search results"
                aria-label="Search results"
                role="button"
                name="results"
                aria-expanded={isExpanded}
              />
              <ReactTooltip id='controllButton-tip'  disable={isMobile} className ="tooltip"/>
              </div>
            </Box>
          </Flex>
          <div style={linkStyle} hidden={!isExpanded}>
            <Button variant='search' onClick={openResultListModal} styles={{
              'border-radius': '5px',
              'font-weight': 'normal',
              'font-size': '14px',
              'padding': '2px 8px',
              'margin-left': '12px'
            }} >
              View Printable List</Button>
          </div>
          {isExpanded && (
            <SortFilters sortBy={MOCKED_SORT_PARAMS} />
          )}
          <AnimatedBox
            pose={isExpanded ? 'open' : 'closed'}
            cssClass="visibleHeader"
            style={{
              position: 'relative',
              overflowY: "auto",
              overflowX: "hidden",
              padding: isExpanded ? '20px' : 0
            }}
            css={{
              [`@media all and (max-width: ${theme.breakpoints[3]}px)`]: {
                height: isExpanded ? `${mobSize}px !important` : `${mobSize}px`
              },
              [`@media (min-width: ${theme.breakpoints[3]}px) and (max-width: ${theme.breakpoints[2]}px)`]: {
                height: isExpanded ? `${mobSize}px !important` : `${mobSize}px`
              },
              [`@media all and (min-height: 480px) and (max-height: 1000px) and (max-width: ${theme.breakpoints[2]}px)`]: {
                height: isExpanded ? `${mobSizeSmall}px !important` : `${mobSizeSmall}px`
              },
              [`@media all and (min-width: 700px) and (max-width: ${theme.breakpoints[0]}px)`]: {
                height: isExpanded ? `${mobSize}px !important` : `${mobSize}px`
              }
            }}
          >
            {isExpanded && !!results.length && (
                  <AutoSizer>
                    {({ width, height }) => (
                      <div
                        role="presentation"
                        onKeyDown={onItemKeydown}
                        style={{
                          width: 'fit-content',
                          wordBreak:"break-word"
                        }}
                      >
                        <List
                          ref={listRef}
                          width={width}
                          height={height}
                          itemSize={getItemSize}
                          itemCount={sortedResults.length}
                          outerElementType={outerElementType}
                        >
                          {renderLocationDetails}
                        </List>
                      </div>
                    )}
                  </AutoSizer>
            )}
          </AnimatedBox>
        </Box>
      </Card>
    </Flex>
  );
};

Results.defaultProps = {
  results: []
};

export default Results;
