import React, { useContext } from 'react';
import {
  Flex, Box
} from 'rebass';
import get from 'lodash/get';
import { Button, Text } from 'rebass';
import 'styled-components/macro';
import VIEW_ICON from 'assets/images/icons/socials/Lens.svg';
import { Icon } from '../index';
import ReactTooltip from 'react-tooltip';
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const SearchArea = ({
  onClick,styles
}) => {
 
  const searchAction  = () => {
    onClick()
  }

  return (
   <div data-tip= 'Search this area'  data-delay-show='300' data-for='searcharaea-tip'  data-effect = 'solid' alt= 'search this area'>
    <Button 
      variant="direction"
      aria-label='Search this area'
      tabIndex="0"
       onClick={searchAction}
      alignItems="center"
      css={{
        ...styles,
        cursor: 'pointer',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
      }}
    >
      <Icon
         width="12.5px"
         height="20.45px"
        src={VIEW_ICON}
        label='label'
      />
      <Text
        fontSize="small"
        lineHeight={0}
        color="black"
        ml="10px"
      >
        Search this area
      </Text>
      <ReactTooltip id='searcharaea-tip' disable={isMobile}  className ="tooltip"/>
    </Button>
    </div>

  );
};

SearchArea.defaultProps = {
};

export default SearchArea;
