import React from 'react';
import { Image } from 'rebass';
import 'styled-components/macro';

import withFocus from '../../shared/withFocus';
import withRef from '../../shared/withRef';

const Icon = (props) => {
  const {
    src,
    width,
    styles,
    onClick,
    isRounded,
    label,
    forwardedRef
  } = props;

  const defaultBordered = {
    flexShrink: 0,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '100%'
  };

  return (
    <Image
      src={src}
      ref={forwardedRef}
      onClick={onClick}
      width={width}
      css={{
        boxSizing: 'border-box',
        display: 'block',
        ...(isRounded && defaultBordered),
        ...styles
      }}
      {...props}
      alt=''
    />
  );
};

Icon.defaultProps = {
  src: '',
  width: 'auto',
  height: 'auto',
  styles: {},
  onClick: null,
  isRounded: false
};

export const IconWithFocus = withFocus(withRef(Icon));

export default Icon;
