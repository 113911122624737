export const ACTIONS = {
  ATM: 'ATM ',
  BRANCH: 'Branch',
  SUBMIT_SEARCH_BUTTON: 'Search button submit',
  SEND_TO_PHONE: 'Send to phone',
  SEND_TO_EMAIL: 'Send to Email',
  CONSTACT_US: 'Contact Us',
  FILTER: 'Filter'
};

export const CATEGORIES = {
  LOCATION: 'Location',
  SEARCH: 'Search',
  FORM: 'Form'
};
