export default {
  ARROW_PAD: {
    DOWN: 'ArrowDown',
    LEFT: 'ArrowLeft',
    RIGHT: 'ArrowRight',
    UP: 'ArrowUp'
  },
  ALPHANUMERIC: {
    FUNCTIONAL: {
      SPACE: ' ',
      TAB: 'Tab',
      ENTER: 'Enter'
    }
  },
  FUNCTION: {
    ESCAPE: 'Escape'
  }
};
