import React, { useEffect, useRef } from 'react';
import { Box, Card, Flex, Heading, Text } from 'rebass';

import CLOSE_ICON from 'assets/images/icons/arrows/close-icon.svg';
import theme from 'styles/theme';
import 'styled-components/macro';
import keyboardCodes from 'constants/keyboardCodes';
import { IconWithFocus } from '..';
import withRef from '../../shared/withRef';
import useFontScaling from "../../../../hooks/useFontScaling";

const { ENTER, SPACE } = keyboardCodes.ALPHANUMERIC.FUNCTIONAL;

const CloseButton = withRef(IconWithFocus);
const IOS_PLATFORMS = {
  IPHONE: 'iPhone',
  IPOD: 'iPod',
  IPAD: 'iPad'
};

const LocationDetailsModal = ({
  onClose, children, label, open, cssClass, id, ...others
}) => {
  const closeRef = useRef(null);
  const elementRefs = useRef({});
  useFontScaling(elementRefs);

  useEffect(() => {
    if (open && closeRef.current) {
      closeRef.current.focus();
    }
  }, [open]);

  const handleOnKeyDown = ({ key }) => {
    if ([SPACE, ENTER].includes(key)) {
      onClose();
    }
  };
  const isIOsPlatform = () => {
    // eslint-disable-next-line no-undef
    const checkPlatform = platform => navigator.platform.indexOf(platform) !== -1;
    const isIOSPlatform = Object
      .values(IOS_PLATFORMS)
      .some(platform => checkPlatform(platform));

    if (isIOSPlatform) {
      return true 
    }else{
      return false 
    }
  }

  return (
    <Card
      className={cssClass}
      backgroundColor="white"
      boxShadow="light"
      borderRadius={1}
      width={theme.widths.blockWidth}
      minHeight={theme.heights.blockHeight}
      p="medium"
      css={{
        position: 'relative',
        bottom: 0,
        zIndex: 3,
        height: '100%',

        [`@media all and (max-width: ${theme.breakpoints[0]}px)`]: {
          position: 'fixed',
          height: '100vh',
          top: 0,
          left: 0,
          overflow: 'auto',
          '&.visibleCenterModal': {
            height: '25%',
            top: '35%',
            left: '25%'
          }
        },

        [`@media all and (max-width: ${theme.breakpoints[6]}px)`]: {
          width: '100%',
          height: 'fit-content',
          '&.visibleHeader': {
            bottom: 0
          },
          /**class handles for location details header */
          '&.visibleHeaderforDetails': {
            bottom: 0
          },
          '&.visibleCenterModal': {
            height: '20%',
            top: '35%',
            left: 0
          },
          position:'fixed',
          display: 'block',
          width: '100%',
          height: '100%',
          left: 0,
          right: 0,
          background: 'rgba(52, 52, 52, 0.8)',
          padding: '0px'
        }
      }}
      tabindex={-1}
      {...others}
    >
      <Box
        width={1}
        bg="white"
        alignItems={'center'}
        css={{
          [`@media all and (max-width: ${theme.breakpoints[6]}px)`]: {
           paddingLeft:'17px',
           paddingRight : '17px',
           paddingTop : '10px',
           paddingBottom : '20px',
           margin: 0, 
           position: 'absolute',
           top: '50%',
           transform : 'translateY(-50%)'
          }
        }}      
      > 
      
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p={0}
      >
        <Heading
          lineHeight={3}
          fontFamily="sfPro"
          fontWeight="bold"
          fontSize="large"
          tabIndex="-1"
          aria-label={label}
          id={id}
          pb={15}
          ref={(ref) => (elementRefs.current.label = ref)}
        >
          {label}
        </Heading>
        <CloseButton
          ref={closeRef}
          tabIndex="0"
          styles={{
            cursor: 'pointer'
          }}
          onClick={onClose}
          onKeyDown={handleOnKeyDown}
          width="18px"
          height="18px"
          src={CLOSE_ICON}
          aria-label={`Close ${label}`}
          name ={`Close ${label}`}
          role="button"
        />
      </Flex>
      {children}
      </Box>
    </Card>
  );
};

export default LocationDetailsModal;
