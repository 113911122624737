import React, { useRef, useEffect } from 'react';
import {
  Box, Card, Flex, Image
} from 'rebass';
import 'styled-components/macro';
import {css} from 'styled-components';

import theme from 'styles/theme';
import useWindowWidth from 'hooks/useWindowWidth';
import SEARCH_ICON from 'assets/images/icons/search.svg';

const MOBILE_PLACEHOLDER = 'Enter Address or Zip Code';
const DESKTOP_PLACEHOLDER = 'Enter Address or Zip Code';

const AutocompleteInput = ({ inputProps, ...others }) => {
  const [width] = useWindowWidth();
  const inputRef = useRef(null);

  //commented to remove the focus to input field on the initial load
  //useEffect(() => inputRef.current.focus(), []);

  const inputPlaceholder = width > theme.breakpoints[0]
    ? DESKTOP_PLACEHOLDER
    : MOBILE_PLACEHOLDER;

  return (
    <Card
      bg="greyLight"
      borderRadius={4}
      widths={1}
      p="small"
      fontFamily="sfPro"
      css={{
        '.keyboard-navigation &:focus-within': {
          outline: theme.outline,
          outlineOffset: theme.outlineOffset
        }
      }}
      {...others}
    >
      <Flex
        justifyContent="flex-start"
      >
        <Image
          src={SEARCH_ICON}
          alt="Search input icon"
          aria-hidden="true"
        />
        <Box
          id="search-places"
          as="input"
          ref={inputRef}
          placeholder={inputPlaceholder}
          {...inputProps}
          aria-label="Search places"
          aria-owns="search-places-listbox"
          aria-controls="search-places-listbox"
          aria-haspopup="listbox"
          style={{
            position: 'relative',
            zIndex: '0',
            height: '100%',
            width: '100%',
            backgroundColor: '#f8f8f8',
            border: 'none',
            outline: 'none',
            color: theme.colors.grey[0],
            fontSize: '16px'
          }}
          css={css`::placeholder
          {
            color: #656565;
          }`}
        />
      </Flex>
    </Card>
  );
};

export default AutocompleteInput;
