/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext, memo, useState, useRef } from 'react';
import parse from 'html-react-parser';
import {
  Box, Card, Flex
} from 'rebass';
import 'styled-components/macro';

import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isPlainObject from 'lodash/isPlainObject';
import head from 'lodash/head';
import each from 'lodash/each';
import partition from 'lodash/partition';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import toLower from 'lodash/toLower';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import EMAIL_ICON from 'assets/images/icons/socials/email.svg';
import WEBSITE_ICON from 'assets/images/icons/socials/viewwebsite.svg';
import BRAND_ICON from 'assets/images/icons/brand-icon-default.svg';
import MOBILE_PHONE_ICON from 'assets/images/icons/mobile-phone-white.svg';
import { ReactComponent as ClosedLocationIcon } from 'assets/images/icons/closed-location-icon.svg';
import { GlobalStateContext } from 'context/global-context';
import { showLocationDetails, setPageIndex, hideLocationDetails, defaultZoomUp, setStaticPlaceCoords } from 'actions';
import { sendLocationTypeAtmEvent, sendLocationTypeBranchEvent } from 'services/analytics';
import { ThemeContext } from 'styled-components';

import theme from '../../../../styles/theme';
import LocationHeader from './LocationHeader';
import LocationPhone from './LocationPhone';
import LocationInfoItem from './LocationInfoItem';
import LocationDistance from './LocationDistance';
import LocationWorkingHours from './LocationWorkingHours';
import CheckboxCheckedIcon from '../CheckboxCheckedIcon/CheckboxCheckedIcon';
import Tabs from '../Tabs';
import { Button, Arrow } from '..';
import withMeasuredHeight from '../../shared/withMeasuredHeight';
import withRef from '../../shared/withRef';
import styled from 'styled-components';
import keyboardCodes from 'constants/keyboardCodes';
import useFontScaling from "../../../../hooks/useFontScaling";
import ServiceDetails from './ServiceDetails'
const { ENTER } = keyboardCodes.ALPHANUMERIC.FUNCTIONAL;
const SHORT_DAYS_OF_WEEK = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const IOS_PLATFORMS = {
  IPHONE: 'iPhone',
  IPOD: 'iPod',
  IPAD: 'iPad'
};

const ClosedInfoBox = styled.div`
  background: #FFDC61;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  padding: 2px 5px;
  margin-left: 5px;
  text-align: center
`;
const DistanceWidget = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 310px;

  @media only screen and (max-width: 640px) {
    justify-content: space-between;
  }
`;
const getClosedInfoMessage = (restrictedAccess, accessHoursRestriction) => {
  let locationClosedMessage = restrictedAccess ? 'Restricted Access' : null;
  if (accessHoursRestriction) {
    if (restrictedAccess)
      locationClosedMessage = 'Restricted Access/Limited Hours';
    else
      locationClosedMessage = 'Limited Hours';
  }
  return locationClosedMessage;
};

const filterTabsPredicate = (tab, isBranch) => tab.isBranch === isBranch && !tab.hide;

const getWeekDayIndex = (day) => {
  const dayName = find(SHORT_DAYS_OF_WEEK, shortDayName => toLower(day).includes(shortDayName));
  return SHORT_DAYS_OF_WEEK.indexOf(dayName);
};


const splitStringLineBreaks = (data) => {
  const normalizedData= data.replace(/<br\s*\/?>/g, '<br>');
  const result=normalizedData.split('<br>');
  return result;
};


const renderAdditionalInfoSection = (isBranch, location, siteConfig, color, elementRefs) => {
  const {
    workingHours,
    CUSpecific,
    AdditnlDirDtls,
    AccessHoursRestriction,
    RestrictedAccess,
    lat,
    lng,
    websiteUrl,
    EmailID,
    phone,
    DriveUpHrs
  } = location;

  const {
    NEWBRANCHTAB1,
    NEWBRANCHTAB2,
    NEWBRANCHTAB3,
    NEWATMTAB1,
    NEWATMTAB2,
    NEWATMTAB3
  } = siteConfig;

  const isValidJSON = (string) => {
    try {
      const json = JSON.parse(string);
      return isPlainObject(json);
    } catch (e) {
      return false;
    }
  };

  const parseArrayLikeHours = (dataToParse) => {
    const result = [];

    if (head(dataToParse)) {
      each(dataToParse, (item) => {
        const [day, time] = item.split(': ');

        if (isString(day) && time) {
          result.push({
            day,
            time,
            dayIndex: getWeekDayIndex(day)
          });
        }
      });

      return <LocationWorkingHours days={sortBy(result, 'dayIndex')} lat={lat} lng={lng} branchTab={NEWBRANCHTAB2} />;
    }

    if (head(dataToParse).includes(' - ')) {
      const [days, time] = partition(dataToParse, item => !item.includes(':'));
      each(days, (day, index) => {
        result.push({
          day,
          time: time[index],
          dayIndex: getWeekDayIndex(day)
        });
      });
      return <LocationWorkingHours days={sortBy(result, 'dayIndex')} lat={lat} lng={lng} branchTab={NEWBRANCHTAB2} />;
    }

    return dataToParse;
  };

  const parseOtherHours = (json) => {
    const isValidData = isValidJSON(json);
    if (!isValidData) return json;

    const data = JSON.parse(json);
    const { data: dataToParse } = data;

    if (isPlainObject(data) && isEmpty(dataToParse)) {
      const workingSchedule = [
        { day: 'Sunday', dayIndex: 0, time: data.Sunday },
        { day: 'Monday', dayIndex: 1, time: data.Monday },
        { day: 'Tuesday', dayIndex: 2, time: data.Tuesday },
        { day: 'Wednesday', dayIndex: 3, time: data.Wednesday },
        { day: 'Thursday', dayIndex: 4, time: data.Thursday },
        { day: 'Friday', dayIndex: 5, time: data.Friday },
        { day: 'Saturday', dayIndex: 6, time: data.Saturday }
      ];
      return <LocationWorkingHours days={workingSchedule} lat={lat} lng={lng} branchTab={NEWBRANCHTAB2} />;
    }

    if (isPlainObject(data) && !isEmpty(dataToParse)) {
      return parseArrayLikeHours(dataToParse);
    }

    return parse(data);
  };
  const tabs = [
    {
      label: NEWBRANCHTAB1,
      content: props => (
        <LocationWorkingHours {...props} lat={lat} lng={lng} branchTab={NEWBRANCHTAB1} />
      ),
      id: 'NEWBRANCHTAB1',
      isBranch: true,
      contentProps: {
        days: workingHours
      },
      hide: workingHours.every(day => day.time === 'N/A') || isEmpty(NEWBRANCHTAB1)
    },
    {
      label: NEWBRANCHTAB2,
      content: props => (
        <LocationWorkingHours {...props} lat={lat} lng={lng} branchTab={NEWBRANCHTAB2} />
      ),
      id: 'NEWBRANCHTAB2',
      isBranch: true,
      contentProps: {
        days: DriveUpHrs
      },
      hide: DriveUpHrs.every(day => day.time === 'N/A') || isEmpty(NEWBRANCHTAB2)
    },
    {
      label: NEWBRANCHTAB3,
      content: ({ branchServices }) => {
        return (<ul>
          {
            branchServices.map((item, index) => (
              <ServiceDetails item={item} color={color} index={index} />
            ))
          }
        </ul>)
      },
      contentProps: {
        branchServices: (AdditnlDirDtls) ? splitStringLineBreaks(AdditnlDirDtls): null
      },
      id: 'NEWBRANCHTAB3',
      isBranch: true,
      hide: isNil(AdditnlDirDtls)
    },
    {
      label: NEWATMTAB1,
      content: props => (
        <LocationWorkingHours {...props} lat={lat} lng={lng} branchTab={NEWATMTAB1} />
      ),
      id: 'NEWATMTAB1',
      isBranch: false,
      contentProps: {
        days: workingHours
      },
      hide: isEmpty(NEWATMTAB1) || workingHours.every(day => day.time === 'N/A')
    },
    {
      label: NEWATMTAB2,
      content: props => (
        <LocationWorkingHours {...props} lat={lat} lng={lng} branchTab={NEWATMTAB2} />
      ),
      id: 'NEWATMTAB2',
      isBranch: false,
      contentProps: {
        days: DriveUpHrs
      },
      hide: isEmpty(NEWATMTAB2) || DriveUpHrs.every(day => day.time === 'N/A')
    },
    {
      label: NEWATMTAB3,
      content: ({ atmServices }) => {
        return (<ul>
          {
            atmServices.map((item, index) => (
              <ServiceDetails item={item} color={color} index={index} />
            ))
          }
        </ul>)
      },
      id: 'NEWATMTAB3',
      isBranch: false,
      contentProps: {
        atmServices: (AdditnlDirDtls) ? splitStringLineBreaks(AdditnlDirDtls): null
      },
      hide: isNil(AdditnlDirDtls)
    }
  ];

  const locationClosedMessage = getClosedInfoMessage(RestrictedAccess, AccessHoursRestriction);
 /**function to handle LocationInfo size */
  const isAdjustInfoSize = () => {
    if( isBranch && !isEmpty(EmailID) ||(websiteUrl && !isEmpty(websiteUrl))||(phone))
     return true
    else
      return false
  }    
  return (
    <>
      {
        <Tabs>
          {
            map(filter(tabs, tab => filterTabsPredicate(tab, isBranch)), tab => (
              <Box
                key={tab.id}
                isAdjustHeight = {isAdjustInfoSize()}
                label={tab.label}
                ml="20px"
                ref={(ref)=>(elementRefs.current.name = ref)} 
              >
                {tab.content(tab.contentProps)}
              </Box>
            ))
          }
        </Tabs>
      }
    </>
  );
};

const LocationDetails = memo(({
  location = {}, isResultSearch, onSendToPhone,onSendToEmail, onContactUs, onViewWebsite, pageIndex, forwardedRef, themeColor, ...others
}) => {
  const colors = get(useContext(ThemeContext), 'colors', {});
  const elementRefs= useRef({});

  const {
    id,
    name,
    address,
    milesAway,
    phone,
    icon,
    lat,
    lng,
    websiteUrl,
    isBranch,
    AccessHoursRestriction,
    RestrictedAccess,
    EmailID
  } = location;

  const isATM = !isBranch;
  const { state, dispatch } = useContext(GlobalStateContext);
  const siteConfig = get(state, 'siteConfig', {});
  const [nextList, setNextList] = useState(false);
  const shouldRenderAdditionalInfoSection = !isResultSearch
    && !isEmpty(location) && !isEmpty(siteConfig);

  // eslint-disable-next-line no-undef
  const { NETWORK_ID } = window.CRD_GLOBAL;
  const sendLocationTypeEvent = () => {
    if (isBranch) {
      sendLocationTypeBranchEvent(name, NETWORK_ID);
    } else {
      sendLocationTypeAtmEvent(name, NETWORK_ID);
    }
  };
  /** This function return if location has valid additional details */
  const checkDetailInfo = () => {
      if(isNil(state.location.AdditnlDirDtls) && state.location.workingHours.every(day => day.time === 'N/A') && state.location.DriveUpHrs.every(day => day.time === 'N/A')){
        return true
      }
      else if((state.location.AdditnlDirDtls) && (state.location.workingHours.every(day => day.time === 'N/A') && state.location.DriveUpHrs.every(day => day.time === 'N/A'))){
        return false
      }
      else{
      return isNil(state.location.AdditnlDirDtls)  ?  false : (state.location.workingHours.every(day => day.time === 'N/A') && state.location.DriveUpHrs.every(day => day.time === 'N/A')) ? true : false;  
      }
    };
  const onResultSearchArrowClick = () => {
    dispatch(showLocationDetails({ lat, lng }, location));
    dispatch(setStaticPlaceCoords(true));

    sendLocationTypeEvent();
  };

  // handles view more button click
  const loadNextSet = () => {
    dispatch(hideLocationDetails());
    dispatch(defaultZoomUp());
    dispatch(setPageIndex(parseInt(pageIndex) + 1));
    setTimeout(() => {
      document.getElementsByClassName('listDiv')[0].scrollTop = '0'
    }, 500)
  };

  // handles view previous button click
  const loadPreviousSet = () => {
    dispatch(hideLocationDetails());
    dispatch(defaultZoomUp());
    dispatch(setPageIndex(parseInt(pageIndex) - 1));
  }

  const onGetDirectionClick = () => {
    // eslint-disable-next-line no-undef
    const checkPlatform = platform => navigator.platform.indexOf(platform) !== -1;
    const isIOSPlatform = Object
      .values(IOS_PLATFORMS)
      .some(platform => checkPlatform(platform));

    if (isIOSPlatform) {
      // eslint-disable-next-line no-undef
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`);
    }
    // eslint-disable-next-line no-undef
    window.open(`https://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`);
  };

  if (id === 'viewmore') {
    if (document.getElementsByClassName('listDiv') && document.getElementsByClassName('listDiv').length > 0) {
      let className = document.getElementsByClassName('listDiv')[0].firstChild.className
      if (className.split(' ').indexOf('viewMoreButton') < 0) {
        document.getElementsByClassName('listDiv')[0].firstChild.className += ' viewMoreButton';
      }

    }
  }

  const locationClosedMessage = getClosedInfoMessage(RestrictedAccess, AccessHoursRestriction);

  // tab accessability click handle for view more and previous button
  const onKeyDownViewMorePrevious = (e) => {
    const { key } = e;

    e.preventDefault();
    if (key === ENTER || key === ' ') {
      if (e.target.value === 'View More')
        loadNextSet();
      else
        loadPreviousSet();
    }
  };
  
  useFontScaling(elementRefs);

  return (
    (id !== 'viewmore' && id !== 'viewprevious' ? <Card
      ref={forwardedRef}
      {...others}
      css={{
        backgroundColor: 'white',
        background: ((id === get(state, 'isSelected') || id === get(state, 'isFocused')) && isResultSearch)
          ? `${theme.colors.greenWithOpacity}`
          : ''
      }}
    >
      <Box
        css={{
          position: 'relative',
          cursor: isResultSearch ? 'pointer' : 'default'
        }}
        onClick={onResultSearchArrowClick}
      >
        <LocationHeader
          {...{
            name,
            address,
            icon,
            isATM,
            websiteUrl
          }}
        />
        {isResultSearch && <Arrow onClick={onResultSearchArrowClick} name={name} address={address} />}
      </Box>
      <Flex
        flexDirection="column"
        flexWrap="wrap"
        pb="medium"
        paddingBottom="5px"
      >
        <LocationDistance
          isResultSearch={isResultSearch}
          milesAway={milesAway}
          onClick={onGetDirectionClick}
          closedInfo={isResultSearch ? locationClosedMessage : null}
          name={name}
          address={address}
        />
        {!isResultSearch ? (
          <Fragment>
            { phone ? (<LocationPhone phone={phone} />) : null}      
          </Fragment>
        ) : null}
        {

          locationClosedMessage ?
            <Fragment>
              <Flex
                alignItems="center"
                mt="5px"
                justifyContent="space-between"
                ref={(ref)=>(elementRefs.current.name = ref)}
              >
                <DistanceWidget>
                  <Flex>
                    <ClosedInfoBox ref={(ref) => (elementRefs.current.closedInfoMessage = ref)}>
                      {locationClosedMessage}
                    </ClosedInfoBox>
                  </Flex>
                </DistanceWidget>
              </Flex>
            </Fragment>

            : null
        }
        {!isResultSearch ? (
          <Fragment>
            <Flex
              alignItems="center"
              mt="5px"
              justifyContent="space-between"
              flex = "2"
              flexWrap='wrap'
            >
              <LocationInfoItem
                key="Phone"
                label="Send to Phone"
                width="12.5px"
                height="20.45px"
                toolTip="Send to Phone"
                src={MOBILE_PHONE_ICON}
                onClick={onSendToPhone}
                styles={{
                  backgroundColor: colors.primaryButtonColor,
                  color: 'white',
                  borderRadius: '5px',
                  padding: '5px 5px',
                  marginRight: '15px',
                  width: '150px',
                  marginTop:'5px'
                }}
              />
               <LocationInfoItem
                key="email"
                label="Send to Email"
                width="12px"
                height="20.45px"
                src={EMAIL_ICON}
                onClick={onSendToEmail}
                toolTip="Send to Email"
                styles={{
                  backgroundColor: colors.primaryButtonColor,
                  color: 'white',
                  borderRadius: '5px',
                  padding: '5px   5px',
                  width: '150px',
                  marginTop:'5px'
                }}
               />
              {
                isBranch && !isEmpty(EmailID)
                  ? (
                    <LocationInfoItem
                      key="Contact"
                      label="Contact Branch"
                      width="18px"
                      height="18px"
                      toolTip="Contact Branch"
                      src={EMAIL_ICON}
                      onClick={onContactUs}
                      styles={{
                        backgroundColor: colors.primaryButtonColor,
                        color: 'white',
                        borderRadius: '5px',
                        padding: '5px 5px',
                        width: '150px',
                        marginTop:'5px'
                      }}
                    />
                  ) : null
              }
            {
              websiteUrl && !isEmpty(websiteUrl)
                ? (
                      <LocationInfoItem
                        key="Website Link"
                        label="View Website"
                        
                        width="18px"
                        height="18px"
                        src={WEBSITE_ICON}
                        onClick={onViewWebsite}
                        toolTip="View Website"
                        styles={{
                          backgroundColor: colors.primaryButtonColor,
                          color: 'white',
                          borderRadius: '5px',
                          padding: '5px 5px',
                          width: '150px',
                          marginTop:'5px'
                        }}
                      />
          
                ) : null
            }
            </Flex>
          </Fragment>
        ) : null}
      </Flex>
      {
        shouldRenderAdditionalInfoSection ? (
          <Fragment>
            <Flex
              css={{
                borderBottom: checkDetailInfo() ? '' : '1px solid #E2E2E2'
              }}
            />
            <Box mt="5px">
              {
                renderAdditionalInfoSection(
                  isBranch, location, siteConfig, colors.primaryButtonColor, elementRefs
                )
              }
            </Box>
          </Fragment>
        ) : null
      }
    </Card> : id === 'viewmore' ?
    <div className='pageButtons viewMoreDiv'>
        <Button variant="search" value='View More' onClick={loadNextSet} onKeyDown={onKeyDownViewMorePrevious} >
          View More  &gt;&gt;
        </Button>
    </div> :
    <div className='pageButtons viewPreviousButton viewPreviousDiv'>
      <Button variant="search" value='View Previous' onClick={loadPreviousSet} onKeyDown={onKeyDownViewMorePrevious}>
          &lt;&lt;   View Previous
          </Button>
    </div>)
  );
});

LocationDetails.defaultProps = {
  id: '',
  locationName: '',
  address: '',
  milesAway: '',
  phone: '',
  websiteUrl: '',
  imageURL: BRAND_ICON,
  socials: [],
  lobbyHours: [],
  isResultSearch: false,
  isBranch: false,
  onSendToPhone: null,
  onSendToEmail:null,
  onContactUs: null,
  onViewWebsite: null
};

export const LocationDetailsWithHeight = withMeasuredHeight(withRef(LocationDetails));

export default LocationDetails;
