import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box } from 'rebass';
import mapStyles from 'styles/mapStyles';
import 'styled-components/macro';
import isMobileDevice from '../../../utils/deviceDetector';
import { setStaticPlaceCoords } from 'actions';
import { GlobalStateContext } from 'context/global-context';

// eslint-disable-next-line no-undef
const { GOOGLE_MAPS_API_KEY } = window.CRD_GLOBAL;

const Map = ({
  children, mapCenter, zoom, onMapLoaded, onUserChangedMapCenter, onUserChangedZoom
}) => {
  const MAP_KEY = { key: GOOGLE_MAPS_API_KEY };
  const { state,dispatch } = useContext(GlobalStateContext);
  const createMapOption = () => ({
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false
  });
  
  var browserZoomLevel = window.devicePixelRatio;
  const handleApiLoaded = (map) => {
    onMapLoaded(map);
    dispatch(setStaticPlaceCoords(true));
  };

  const onChangeMapCenter = (event) => {
    const lat = parseFloat((event.center.lat).toFixed(6));
    // eslint-disable-next-line no-mixed-operators
    var lng = 0;
    if(event.center.lng < -180){
      lng = (parseFloat((event.center.lng).toFixed(6)) - 180) % 360 + 180 ;
    }else if(event.center.lng > 180){
      lng = (parseFloat((event.center.lng).toFixed(6)) + 180) % 360 - 180 ;
    }else{
      lng = parseFloat((event.center.lng).toFixed(6)) ;
    }
    onUserChangedZoom(event.zoom)
    onUserChangedMapCenter(
      lat,
      lng
    );
  };
  var browserMapHeight = 100;
  var browserMapwidth = 100;
  if(browserZoomLevel > 1) {
    browserMapHeight = browserMapHeight * Math.round(browserZoomLevel);
    if( window.self!==window.top)
    {
      browserMapHeight=100;
    }
  }
  var calCulatedZoom = (Math.round(window.devicePixelRatio * 100)*20)/100;
  var calCulatedZoomWidth = Math.round(window.devicePixelRatio * 100) - calCulatedZoom
  if(calCulatedZoomWidth < 100 || calCulatedZoom < 35) {
    calCulatedZoomWidth = 100;
  }
  return (
    <Box
      css={{
        height: isMobileDevice() ? 'calc(var(--vh, 1vh) * 100)' : 'calc(var(--vh, 1vh) *'+ 100 +')',
        width: '100%'
      }}
    >
      <GoogleMapReact
        id="locator-map"
        aria-label="div Map"
        aria-model="true"
        bootstrapURLKeys={MAP_KEY}
        zoom={zoom}
        onChange={onChangeMapCenter}
        center={mapCenter}
        options={createMapOption}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
      >
        {children}
      </GoogleMapReact>
    </Box>
  );
};

export default Map;

Map.defaultProps = {
  mapCenter: {
    lat: 32.783,
    lng: -96.806
  },
  zoom: 11
};
