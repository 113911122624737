export default {
  TOGGLE_FILTERS_MODAL: 'TOGGLE_FILTERS_MODAL',
  TOGGLE_PHONE_MODAL: 'TOGGLE_PHONE_MODAL',
  FETCH_SEARCH_LOCATION: 'FETCH_SEARCH_LOCATION',
  TOGGLE_EMAIL_MODAL: 'TOGGLE_EMAIL_MODAL',
  TOGGLE_SMS_MODAL_ERROR: 'TOGGLE_SMS_MODAL_ERROR',
  CALL_SORT_API:'CALL_SORT_API',
  TOGGLE_EMAIL_MODAL_ERROR:'TOGGLE_EMAIL_MODAL_ERROR',
  TOGGLE_CONTACT_US_MODAL: 'TOGGLE_CONTACT_US_MODAL',
  TOGGLE_RESULT_LIST_MODAL: 'TOGGLE_RESULT_LIST_MODAL',
  
  SHOW_LOCATION_DETAILS: 'SHOW_LOCATION_DETAILS',
  HIDE_LOCATION_DETAILS: 'CLOSE_LOCATION_DETAILS',
  SET_FOCUSED_LOCATION: 'SET_FOCUSED_LOCATION',

  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',

  FIND_MY_LOCATION: 'FIND_MY_LOCATION',
  SET_APPLIED_FILTERS: 'SET_APPLIED_FILTERS',
  SET_PLACE_COORDS: 'SET_PLACE_COORDS',
  SET_CURRENT_USER_COORDS: 'SET_CURRENT_USER_COORDS',
  SET_PAGE_INDEX: 'SET_PAGE_INDEX',
  SET_SORT_BY: 'SET_SORT_BY',
  MAP_LOCATION_ZOOM: 'MAP_LOCATION_ZOOM',
  MANUAL_ZOOM_LEVEL: 'MANUAL_ZOOM_LEVEL',
  CLOSE_MODALS: 'CLOSE_MODALS',
  DEFAULT_ZOOM_UP: 'DEFAULT_ZOOM_UP',
  TOGGLE_SMS_MODAL: 'TOGGLE_SMS_MODAL',
  SET_ATM_COUNTS_DATA: 'SET_ATM_COUNTS_DATA',
  SET_SITE_CONFIG: 'SET_SITE_CONFIG',
  SET_FILTERS_AVAILABILITY: 'SET_FILTERS_AVAILABILITY',
  SET_SEARCH_THIS_AREA_CLICKED : 'SET_SEARCH_THIS_AREA_CLICKED',
  SET_SEARCHADDRESS_FROM_GEOCODER:'SET_SEARCHADDRESS_FROM_GEOCODER',
  SET_STATIC_PLACE_COORDS:'SET_STATIC_PLACE_COORDS'
};
