import React, {useRef} from 'react';
import {
  Flex, Box, Text, Link
} from 'rebass';
import Icon from '../../Icon/Icon';
import useFontScaling from "../../../../../hooks/useFontScaling";

const LocationHeader = ({
  icon, name, address, websiteUrl
}) => {
  const commonStyles = {
    flexShrink: 0,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '100%'
  };

  const elementRefs= useRef({});

  useFontScaling(elementRefs);

  return (
    <Flex
      mb="5px"
    >
      <Box
        style={{
          ...commonStyles,
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          borderRadius: '50%'
        }}
        width="40px"
      >
        <Icon
          src={icon}
          label={name}
          height="40px"
          width="40px"
          isBordered
        />
      </Box>
      <Box
        ml="5px"
        width={[1, 240]}
        mr={[40, 0]}
      >
        <Text
          fontWeight="bold"
          fontSize="regular"
          lineHeight={2}
          mb="5px"
          ref={(ref)=>(elementRefs.current.name = ref)}
        >
          {
              name
          }
        </Text>
        <Text
          color="#585858"
          fontSize="small"
          lineHeight={0}
          ref={(ref)=>(elementRefs.current.address = ref)}
        >
          {address}
        </Text>
      </Box>
    </Flex>
  );
};

export default LocationHeader;
