import React from 'react';
import { Flex, Link } from 'rebass';
import 'styled-components/macro';

import { Icon } from '../..';

const LocationSocials = ({ socials }) => {
  if (!socials.length) return null;

  return (
    <Flex
      height="24px"
      justifyContent="space-around"
      ml="20px"
    >
      {
        socials.map(({
          label,
          src,
          href,
          onClick
        }) => (
          <Link
            key={src}
            href={href}
            label={href}
            target="_blank"
            aria-label={label}
            alt={href}
            css={{
              width: '40px',
              height: '24px',
              textAlign: 'center'
            }}
          >
            <Icon
              key={src}
              width="24px"
              height="24px"
              label={href}
              src={src}
              onClick={onClick}
            />
          </Link>
        ))
      }

    </Flex>
  );
};

LocationSocials.defaultProps = {
  socials: []
};

export default LocationSocials;
