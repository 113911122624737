import React, { useContext, useRef } from 'react';
import { Button as BaseButton, Text } from 'rebass';
import get from 'lodash/get';
import 'styled-components/macro';
import { ThemeContext } from 'styled-components';

import withFocus from '../../shared/withFocus';
import withRef from '../../shared/withRef';
import useFontScaling from "../../../../hooks/useFontScaling";


const Button = (props) => {
  const colors = get(useContext(ThemeContext), 'colors', {});
  const { forwardedRef, styles = {},children, type='text', ...others } = props;
  const btnStyles = {
    backgroundColor: colors.primaryButtonColor,
    ...styles
  };

  const elementRefs = useRef({});
  useFontScaling(elementRefs);

  return (
    <BaseButton ref={forwardedRef} css={btnStyles} {...others} >
      {
        (type === 'text') ?
          <Text
            ref={(ref) => (elementRefs.current.more = ref)}
          >      {children}
          </Text> :
          children 
      }  
    </BaseButton>
  );
};

export const ButtonWithFocus = withFocus(withRef(Button));

export default Button;
