import React, { useContext, useState, useRef, useEffect,Fragment } from 'react';
import { GlobalStateContext } from 'context/global-context';
import { toggleResultListModal } from 'actions';
import ReactModal from 'react-modal';
import 'styled-components/macro';

import theme from 'styles/theme';
import { DEFAULT_SORT_OPTION } from '../../../../../constants/general';
import isMobileDevice from '../../../../../utils/deviceDetector'; 
import { useReactToPrint } from 'react-to-print';

import {
  ModalTemplate, Button
} from '../..';
import './index.css';

import ReactTooltip from 'react-tooltip';
import PrintableList from './PrintableList';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// sorting the result
const getSortedResults = (results, sortBy) => {
  if(results.length > 0) {
    if (sortBy === DEFAULT_SORT_OPTION) {
      return [...results].sort((a, b) => (a.milesAway - b.milesAway));
    }
  
    return [...results].sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
  
      if (a.name > b.name) {
        return 1;
      }
  
      return 0;
    });
  }
};

// Modal to list the result with option to print it 
const PopOutWindow = () => {
  const { state: {isResultListModalOpen, sortBy, resultList}, dispatch } = useContext(GlobalStateContext);
  //const isResultListModalOpen = isResultListModalOpen;
  const results = resultList && resultList.listData ? resultList.listData : resultList;
  const [sortedResults, setSortedResults] = useState([]);
  //modal close function
  const toggleModalWindow = () => {
    dispatch(toggleResultListModal(results));
  };
  const printListRef = useRef(null);
  
  const onAfterOpen = () => {
    // eslint-disable-next-line no-undef
  }
  
  useEffect(() => {
    if(results.length > 0) {
      setSortedResults(getSortedResults(results, sortBy))
    }
  }, [results, sortBy]);
  
  // Printing the modal content 
  const handlePrint = useReactToPrint({
    content: () => printListRef.current,
  });

  
  return (
    <ReactModal
      isOpen={isResultListModalOpen}
      onRequestClose={toggleModalWindow}
      onAfterOpen={onAfterOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isMobileDevice() ? 'none' : '#313131cc',
          zIndex: 2
        },
        content: {
          position: 'initial',
          padding: 'none',
          border: 'none',
          borderRadius: theme.radii[1],
          overflow: 'hidden'
        }
      }}
      contentLabel="View Printable List Modal"
    >
      <ModalTemplate
        label="Printable List"
        onClose={toggleModalWindow}
        style={{
          height: isMobileDevice() ? '80vh' : '600px'
        }}
      >
      <Button variant='search' onClick={handlePrint} className='printButton' data-for='printButton-tip' data-effect = 'solid'>Print</Button>
      <ReactTooltip id='printButton-tip'  disable={isMobile} className ="tooltip"/>
      {(
        <ul ref={printListRef} className='ListView'>
          {(sortedResults.length > 0) ? [...sortedResults].slice(0, 100).map((item, index)=>(
            <PrintableList item={item} key={index}/>
          )) : ''}
      </ul>
      )}
      </ModalTemplate>
    </ReactModal>
  );
};


PopOutWindow.defaultProps = {
  results: [],
  isResultListModalOpen: false
}
export default PopOutWindow;
