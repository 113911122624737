import React, { useContext } from 'react';
import { Flex, Text, Link } from 'rebass';
import get from 'lodash/get';
import theme from 'styles/theme';
import { ThemeContext } from 'styled-components';
import 'styled-components/macro';

const FilterControls = ({ handleSelectAll, handleUnselectAll, selectedFilters, isAllFiltersSelected }) => {
  const colors = get(useContext(ThemeContext), 'colors', {});

  const renderText = (label, handlerFunction, pointerEvents, opacity ) => (
    <Link
      href="/"
      value={label}
      color={colors.primaryButtonColor}
      fontSize="small"
      onClick={handlerFunction}
      css={{
        cursor: 'pointer',
        border: 'none',
        padding: 0,
        textDecoration: 'none',
        background: 'none',
        fontWeight: 'normal',
        '&:focus': {
          outline: theme.outline,
          outlineOffset: theme.outlineOffset
        },
        pointerEvents: pointerEvents,
        opacity: opacity
      }}
    >
      {label}
    </Link>
  );

  return (
    <>
      <Flex
        justyfyContent="center"
        mb="small"
      >
        {renderText('Select All', handleSelectAll, isAllFiltersSelected===true ? 'none' : 'auto',
        isAllFiltersSelected===true ? 0.4 : 1.0)}
        <Text
          mx="small"
          width="1px"
          height="30px"
          css={{
            backgroundColor: theme.colors.grey[0]
          }}
        />
        {renderText('Clear All', handleUnselectAll,  selectedFilters.length>0 ? 'auto':'none',
        selectedFilters.length>0 ? 1.0 : 0.4)}
      </Flex>
      <Flex>
        <Text
          css={{
            fontSize: '12px',
            marginBottom: '20px'
          }}
        >
          Select at least one search filter
        </Text>
      </Flex>
    </>
  );
};

export default FilterControls;
