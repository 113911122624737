import React, { useState, useContext, useRef } from 'react';
import {
  Flex, Box, Text
} from 'rebass';
import  SocialButton  from '../Button/SocialButton';
import isEmpty from 'lodash/isEmpty';
import { sendToEmailOnSubmitEvent } from 'services/analytics';
import { sendToEmail } from 'services/smsService';
import {toggleErrorModal , toggleEmailModal ,isSendEmailError } from 'actions';
import 'react-phone-number-input/style.css';
import { GlobalStateContext } from 'context/global-context';
import EmailInput from './EmailInput';
import Recaptcha from './Recaptcha';
import get from 'lodash/get';
import { ThemeContext } from 'styled-components';
import useFontScaling from "../../../../hooks/useFontScaling";

const SendEmail = () => {
  // eslint-disable-next-line no-unused-vars
  const { state: { location: { LocationID } }, dispatch } = useContext(GlobalStateContext);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);
  const colors = get(useContext(ThemeContext), 'colors', {});
  const elementRefs= useRef({});

  const openErroModal = (errorMessage) => { 
    dispatch(toggleEmailModal())
    dispatch(toggleErrorModal(errorMessage)) 
  };

  const onSubmitEmail = async () => {
    // eslint-disable-next-line no-undef
    const { NETWORK_ID } = window.CRD_GLOBAL;
    let response;
    
    if (!isEmpty(email) && !sendButtonClicked) {
      sendToEmailOnSubmitEvent(NETWORK_ID);
      setSendButtonClicked(true);
      try {
        response = await sendToEmail({
          LocationId: LocationID,
          NetworkId: NETWORK_ID,
          ToEmail: email
        });

        if (response.status === 200) {
          const successMessage = {errorTitle: "Send to Email", errorMessage: "Send to email form has been successfully submitted"}
          openErroModal(successMessage);
          dispatch(isSendEmailError(false))
        } else {
          const errorMessage = {errorTitle: "Something Went Wrong", errorMessage: "Please try again."}
          openErroModal(errorMessage);
          dispatch(isSendEmailError(true))
        }

      } catch (err) {
        const errorMessage = {errorTitle: "Something Went Wrong", errorMessage: "Please try again."}
        openErroModal(errorMessage);
        dispatch(isSendEmailError(true))
      }
    }
  };
  useFontScaling(elementRefs);

  return (
    <Flex
      flexDirection="column"
      css={{
        width: '335px',
        '@media all and (max-width: 620px)': {
          width: '100%'
        }
      }}
    >
      
        <div>
          <Text
            fontFamily="sfPro"
            mb="30px"
          >
            Provide your email address   below to receive the details of the location.
          </Text>
          <span  style={{
            marginBottom: '30px',
            display: 'block'
          }}>Required fields are marked with an <span  style={{
            color: 'red'
          }}>*</span></span>
          <Box
            mb="25px"
          >
            <EmailInput
              onChange={setEmail}
              isEmailValid={setIsEmailValid}
              email={setEmail}
            />
          </Box>
          <Box
            alignSelf="center"
            mb="60px"
            style={{
              overflow: 'hidden'
            }}
          >
            {<Recaptcha
              onRecaptchaVerify={setIsRecaptchaValid}
            />}
          </Box>
          <SocialButton
            onClick={isEmailValid   && isRecaptchaValid && !sendButtonClicked ? onSubmitEmail : ''}
            backgroundColor = {isEmailValid   && isRecaptchaValid && !sendButtonClicked ? colors.primaryButtonColor : '#585858'}  
            aria-disabled= {isEmailValid   && isRecaptchaValid && !sendButtonClicked ? false : true}
            aria-label='Send Email'
            role='button'
          >
            <Text
              fontFamily="sfPro"
              ref={(ref)=>(elementRefs.current.sendEmail = ref)}
            >
              Send Email Message
            </Text>
          </SocialButton>
          <Text
            fontFamily="sfPro"
            mb="40px"
            mt ="5px"
          >
            The email you entered will not be collected or used for any marketing purpose.
          </Text>
        </div>
    </Flex>
  );
};

export default SendEmail;
