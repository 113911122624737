import React, {
  useState, useContext, memo, useEffect
} from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Box } from 'rebass';
import { GlobalStateContext } from 'context/global-context';
import { setAppliedFilters, setFiltersAvailability, setStaticPlaceCoords } from 'actions';
import { sendFilterEvent } from 'services/analytics';
import SearchFilters from './SearchFilters';
import FilterControls from './FilterControls';
import { Button } from '..';

// eslint-disable-next-line no-undef
const { GET_FILTERS_API_URL } = window.CRD_GLOBAL;

const Filters = memo((props) => {
  const { networkId } = props;
  const { state, dispatch } = useContext(GlobalStateContext);
  const [selectedFilters, setSelectedFilters] = useState(get(state, 'appliedFilters'));
  const [filters, setFilters] = useState([]);
  const [isAllFiltersSelected, setIsAllFiltersSelected] = useState(true);
  const getNewSearchFilters = (label) => {
    const isChecked = selectedFilters.find(({ name }) => name === label);
    if (isChecked) {
      return selectedFilters.filter(({ name }) => name !== label);
    }

    const filterData = filters.find(item => item.label === label);
    return [...selectedFilters, { name: label, value: filterData.value }];
  };
  /* retain filter data */
  useEffect(() => {
    setSelectedFilters(get(state, 'appliedFilters'));
    if (filters.length === selectedFilters.length) {
      setIsAllFiltersSelected(true);
    } else {
      setIsAllFiltersSelected(false);
    }
  }, [state.isFiltersOpen]);

  function hasQueryParams(url) {
    return url.indexOf('?') !== -1;
  }

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const options = {
          method: 'GET',
          headers: {
            Accept: 'aplication/json',
            'Content-Type': 'application/json'
          }
        };

        const configUrl = `${GET_FILTERS_API_URL}/${networkId}`;

        // eslint-disable-next-line no-undef
        const response = await fetch(configUrl, options);
        const filtersJson = await response.json();

        const isFiltersAvailable = !isEmpty(filtersJson.data);
        dispatch(setFiltersAvailability(isFiltersAvailable));

        if (isFiltersAvailable) {
          const filtersArr = filtersJson.data.map(({
            SearchByMobileName,
            SearchByValue,
            SearchFilterImageUrl
          }) => (
            {
              src: SearchFilterImageUrl,
              label: SearchByMobileName,
              value: SearchByValue
            }
          ));
          setFilters(filtersArr);
          if (!hasQueryParams(window.location.href)) {
            const initFilters = filtersJson.data.map(({
              SearchByMobileName,
              SearchByValue
            }) => (
              {
                name: SearchByMobileName,
                value: SearchByValue
              }
            ));
            dispatch(setAppliedFilters(initFilters));
            setSelectedFilters(initFilters)
          }
          //filter searchoptions from url
          const valueArray = filtersArr.map(data => data.value)
          var url = new URL(window.location.href);
          const newParams = new URLSearchParams();
          for (const [name, value] of url.searchParams) {
            newParams.append(name.toLowerCase(), value);
          }
          const predefinedFilter = valueArray.filter(item => newParams.get(item.toLowerCase()) == '1')
          if (predefinedFilter.length > 0) {
            const timeout = setTimeout(() => {
              callFilterApi(filtersArr, predefinedFilter)
            }, 1500);
            return () => clearTimeout(timeout);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchFilters();
  }, []);
  /** call filter API with url parameters */
  const callFilterApi = (filtersArr, predefinedFilter) => {
    const filterArray = []
    predefinedFilter.map(item => {
      const selectedAray = filtersArr.filter(data => data.value == item)
      const selecteditem = {
        name: selectedAray[0].label,
        value: selectedAray[0].value
      }
      filterArray.push(selecteditem)
    })
    setSelectedFilters(filterArray)
    dispatch(setAppliedFilters(filterArray));

    setIsAllFiltersSelected(false);
    // send selected filers parameters passed to ga4 on load having url parameters
    const { NETWORK_ID } = window.CRD_GLOBAL;
    const selectedFiltersStr = filterArray.map(({ name }) => name).join(',');
    sendFilterEvent(selectedFiltersStr, NETWORK_ID);
  }

  const handleFiltersChange = (label) => {
    const newFilters = getNewSearchFilters(label);
    if (filters.length === newFilters.length) {
      setIsAllFiltersSelected(true);
    }else{
      setIsAllFiltersSelected(false);
    }
    setSelectedFilters(newFilters);
  };

  const applyFiltersHandler = (e) => {
    dispatch(setAppliedFilters(selectedFilters));
    dispatch(setStaticPlaceCoords(true));

    e.preventDefault();

    if (!selectedFilters.length) return;

    // eslint-disable-next-line no-undef
    const { NETWORK_ID } = window.CRD_GLOBAL;
    const selectedFiltersStr = selectedFilters.map(({ name }) => name).join(',');
    sendFilterEvent(selectedFiltersStr, NETWORK_ID);
  };

  const handleUnselectAll = (e) => {
    setSelectedFilters([]);
    setIsAllFiltersSelected(false);
    e.preventDefault();
  };

  const handleSelectAll = (e) => {
    const selectAll = filters.map(el => ({ name: el.label, value: el.value }));
    setSelectedFilters(selectAll);
    setIsAllFiltersSelected(true);

    e.preventDefault();
  };

  return (
    <Box as="form">
      <FilterControls
        handleUnselectAll={handleUnselectAll}
        handleSelectAll={handleSelectAll}
        selectedFilters={selectedFilters}
        isAllFiltersSelected={isAllFiltersSelected}
      />
      <SearchFilters
        facetedNavigation={filters}
        onChange={handleFiltersChange}
        selectedFilters={selectedFilters}
      />
      <Button
        variant="apply"
        mt={['200px', '22px']}
        onClick={applyFiltersHandler}
        styles={{
          pointerEvents: selectedFilters.length>0 ? 'auto' : 'none',
          opacity: selectedFilters.length>0  ? 1.0 : 0.4
        }}        
      >
        Apply
      </Button>
    </Box>
  );
});

export default Filters;
