import React, {useRef} from 'react';
import { Button, Text } from 'rebass';
import { Icon } from '../../index';
import 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import useFontScaling from "../../../../../hooks/useFontScaling";

const LocationInfoItem = (props) => {
  const {
    src,
    width,
    height,
    label,
    styles,
    onClick,
    toolTip
  } = props;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const elementRefs= useRef({});

  useFontScaling(elementRefs);
  return (
    <Button 
      variant="direction"
      aria-label={toolTip}
      tabIndex="0"
      onClick={onClick}
      alignItems="center"
      css={{
        ...styles,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Icon
        width={width}
        height={height}
        src={src}
        label={`${toolTip}`}
      />
      <Text
        fontSize="small"
        lineHeight={0}
        color="white"
        ml="10px"
        ref={(ref)=>(elementRefs.current.label = ref)}
      >
        {label}
      </Text>
      <ReactTooltip id={toolTip} disable={isMobile} className ="tooltip"/>
    </Button>
  );
};

export default LocationInfoItem;
