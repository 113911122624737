import React, { useState } from 'react';
import { Box } from 'rebass';
import {
  Input,
  Label,
  Textarea
} from '@rebass/forms';
const EmailInput = ({ email, isEmailValid }) => {
  const [value, setValue] = useState('');
  const [comment, setComment] = useState('');

  const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      isEmailValid(true);
      return true;
    }
    else {
      isEmailValid(false);
      return false;
    }
  };
  const validateData = (e) => {
    validateEmail(e);
    setValue(e)
    email(e);
  };
  return (
    <Box>
      <Label
        htmlFor="email"
        css={{
          paddingTop: 30,
          paddingBottom: 10,
          '@media all and (max-width: 620px)': {
            paddingTop: 10
          }
        }}
      >
        Email
                <span
          style={{
            color: 'red'
          }}
        >
          *
                </span>
      </Label>
      <Input
        id="email"
        name="email"
        value={value}
        onChange={e => validateData(e.target.value)}
        placeholder="yourname@email.com"
        css={{
          borderBottom: '1px solid #E2E2E2',
          borderColor: '#E2E2E2'
        }}
        alt="Email"
        role="textbox"
      />
    </Box>






  );
};

export default EmailInput;
