import React, { useContext } from 'react';
import { GlobalStateContext } from 'context/global-context';
import ReactModal from 'react-modal';
import theme from 'styles/theme';
import { toggleEmailModal, isSentSmsToggle } from 'actions';
import {
  ModalTemplate
} from '..';
import { sendSmsToPEmailOnLoadEvent } from '../../../../services/analytics';
import SendEmail from '../SendPhone/SendEmail';

const SendToEmailModal = () => {
  const { state: { isEmailOpen, isSmsSent }, dispatch } = useContext(GlobalStateContext);

  const toggleModalWindow = () => {
    dispatch(toggleEmailModal());
  };

  const onAfterOpen = () => {
    // eslint-disable-next-line no-undef
    const { NETWORK_ID } = window.CRD_GLOBAL;
    sendSmsToPEmailOnLoadEvent(NETWORK_ID);
  }

  return (
    <ReactModal
      isOpen={isEmailOpen}
      onRequestClose={toggleModalWindow}
      onAfterOpen={onAfterOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor:  '#313131cc',
          zIndex: 2
        },
        content: {
          position: 'initial',
          padding: 'none',
          border: 'none',
          borderRadius: theme.radii[1],
          overflow: 'hidden'
        }
      }}
      role='dialog'
      
      aria={{
        labelledby:"SentToEmail",
        modal:"true"
      }}
    >
      <ModalTemplate
        label="Send to Email Address"
        onClose={toggleModalWindow}
        id="SentToEmail"
      >
        <SendEmail />
      </ModalTemplate>
    </ReactModal>
  );
};

export default SendToEmailModal;
