import actions from 'constants/actionTypes';
import {
  DEFAULT_ZOOM,
  MAX_ZOOM_LEVEL
} from 'constants/general';

const parseCoordsToFloat = ({ lat, lng }) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng)
});

const {
  SHOW_LOCATION_DETAILS,
  HIDE_LOCATION_DETAILS,
  TOGGLE_SMS_MODAL,
  TOGGLE_SMS_MODAL_ERROR,
  CALL_SORT_API,
  TOGGLE_EMAIL_MODAL_ERROR,
  SET_FOCUSED_LOCATION,
  TOGGLE_PHONE_MODAL,
  FETCH_SEARCH_LOCATION,
  TOGGLE_EMAIL_MODAL,
  TOGGLE_RESULT_LIST_MODAL,
  TOGGLE_CONTACT_US_MODAL,
  TOGGLE_ERROR_MODAL,
  TOGGLE_FILTERS_MODAL,
  FIND_MY_LOCATION,
  SET_PLACE_COORDS,
  SET_CURRENT_USER_COORDS,
  MAP_LOCATION_ZOOM,
  MANUAL_ZOOM_LEVEL,
  DEFAULT_ZOOM_UP,
  SET_APPLIED_FILTERS,
  SET_PAGE_INDEX,
  CLOSE_MODALS,
  SET_SORT_BY,
  SET_ATM_COUNTS_DATA,
  SET_SITE_CONFIG,
  SET_FILTERS_AVAILABILITY,
  SET_SEARCH_THIS_AREA_CLICKED,
  SET_SEARCHADDRESS_FROM_GEOCODER,
  SET_STATIC_PLACE_COORDS
} = actions;

const initialState = {
  location: {},
  isLocationInfoOpen: false,
  isFiltersOpen: false,
  isModalOpen: false,
  isCallSearchApi:false,
  isEmailOpen:false,
  isContactUsModalOpen: false,
  isResultListModalOpen: false,
  isErrorModalOpen: false,
  atmInfo: null,
  isSelected: '',
  isSearchThisAreaClicked: false,
  isStaticPlaceCoords: true
};

const showLocationDetails = (state, { payload, payload: { location: { id: isSelected } } }) => ({
  ...state,
  ...payload,
  isLocationInfoOpen: true,
  isFiltersOpen: false,
  zoom: MAX_ZOOM_LEVEL,
  isSelected
});

const hideLocationDetails = state => ({
  ...state,
  isLocationInfoOpen: false,
  location: {},
  isSelected: ''
});

// sets the default zoom on map
const setDefaultZoom = state => ({
  ...state,
  zoom: DEFAULT_ZOOM
})

const setFocusedLocation = (state, { payload }) => ({
  ...state,
  isFocused: payload.id
});

const toggleFiltersModal = state => ({
  ...state,
  isFiltersOpen: !state.isFiltersOpen,
  isLocationInfoOpen: false
});

const togglePhoneModal = state => ({
  ...state,
  isModalOpen: !state.isModalOpen
});
/**search model*/
const fetchSerachedLocation = (state,{ payload }) => ({
  ...state,
  isCallSearchApi: !state.isCallSearchApi,
  currentSearchAddress: payload.currentSearchAddress,
});

/** email model */
const toggleEmailModal = state => ({
  ...state,
  isEmailOpen: !state.isEmailOpen
});
// setting flag value to show sms modal
const toggleSmsErrorModal = (state, { payload }) => ({
  ...state,
  showBackSmsModal: payload.showBackSmsModal
});

// setting flag value to call sort API
const isCallSortApi = (state, { payload }) => ({
  ...state,
  callSortApi: payload.callSortApi
});
// setting flag value to show sms modal
const toggleEmailErrorModal = (state, { payload }) => ({
  ...state,
  showBackEmailModal: payload.showBackEmailModal
});

const toggleResultListModal = (state, { payload }) => ({
  ...state,
  resultList: payload,
  isResultListModalOpen: !state.isResultListModalOpen
});

const toggleContactUsModal = state => ({
  ...state,
  isContactUsModalOpen: !state.isContactUsModalOpen
});


const toggleErrorModal =  (state, { payload }) => ({
  ...state,
  ...payload,
  isErrorModalOpen: !state.isErrorModalOpen
});

const isSentSmsToggle = state => ({
  ...state,
  isSmsSent: !state.isSmsSent
});

const updateMapPosition = (state, { payload }) => ({
  ...state,
  ...payload,
  zoom: MAX_ZOOM_LEVEL
});

// setting mapcenter on change of usercords
const updateMapLocationZoom = (state, { payload }) => ({
  ...state,
  mapCenter: parseCoordsToFloat(payload.userCoords)
});

//set user manual zoom
const setUserManaualZoomLevel = (state, { payload }) => ({
  ...state,
  zoomLevel: payload.zoomLevel
});

const setPlaceCoords = (state, { payload }) => ({
  ...state,
  ...payload
});

const setATMCountsData = (state, { payload }) => ({
  ...state,
  ...payload
});

const setCurrentUserCoords = (state, { payload }) => ({
  ...state,
  ...payload
});

const setAppliedFilters = (state, { payload }) => ({
  ...state,
  appliedFilters: payload.appliedFilters,
  isFiltersOpen: false
});

// setting pageindex to access during api call
const setPageIndex = (state, { payload }) => ({
  ...state,
  pageIndex: payload.pageIndex
});

const setSortBy = (state, { payload }) => ({
  ...state,
  sortBy: payload.sortBy
});

const closeModals = state => ({
  ...state,
  isFiltersOpen: false,
  isLocationInfoOpen: false
});

const setSiteConfig = (state, { payload }) => ({
  ...state,
  siteConfig: payload
});

const setFiltersAvailability = (state, { payload }) => ({
  ...state,
  ...payload
});

/**To set search this area button clicked action*/
const setSearchAreaFlagClicked = (state, { payload }) => ({
  ...state,
  isSearchThisAreaClicked: payload.isSearchThisAreaClicked
});

//setting address after getting coords from geo coder to call api
const setSearchAddressFromGeoCoder = (state, { payload }) => ({
  ...state,
  searchAddressFromGeoCoder: payload.searchAddressFromGeoCoder,
});

/**To set static place coords*/
const setStaticPlaceCoords = (state, { payload }) => ({
  ...state,
  isStaticPlaceCoords: payload.isStaticPlaceCoords
});

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOCATION_DETAILS:
      return showLocationDetails(state, action);
    case HIDE_LOCATION_DETAILS:
      return hideLocationDetails(state);
    case TOGGLE_SMS_MODAL:
      return isSentSmsToggle(state);
    case SET_FOCUSED_LOCATION:
      return setFocusedLocation(state, action);
    case TOGGLE_PHONE_MODAL:
      return togglePhoneModal(state);
       case FETCH_SEARCH_LOCATION:
      return fetchSerachedLocation(state,action);
    case TOGGLE_EMAIL_MODAL:
      return toggleEmailModal(state);
    case TOGGLE_SMS_MODAL_ERROR:
      return toggleSmsErrorModal(state, action)
      case CALL_SORT_API:
        return isCallSortApi(state, action)
    case TOGGLE_EMAIL_MODAL_ERROR:
      return toggleEmailErrorModal(state, action)
    case TOGGLE_CONTACT_US_MODAL:
      return toggleContactUsModal(state);
    case TOGGLE_RESULT_LIST_MODAL:
      return toggleResultListModal(state, action);
    case TOGGLE_ERROR_MODAL:
      return toggleErrorModal(state, action);
    case TOGGLE_FILTERS_MODAL:
      return toggleFiltersModal(state);
    case SET_PLACE_COORDS:
      return setPlaceCoords(state, action);
    case SET_ATM_COUNTS_DATA:
      return setATMCountsData(state, action);
    case SET_CURRENT_USER_COORDS:
      return setCurrentUserCoords(state, action);
    case MAP_LOCATION_ZOOM:
      return updateMapLocationZoom(state, action);
    case MANUAL_ZOOM_LEVEL:
      return setUserManaualZoomLevel(state, action);
    case DEFAULT_ZOOM_UP:
      return setDefaultZoom(state);
    case FIND_MY_LOCATION:
      return updateMapPosition(state, action);
    case SET_APPLIED_FILTERS:
      return setAppliedFilters(state, action);
    case SET_PAGE_INDEX:
      return setPageIndex(state, action);
    case SET_SORT_BY:
      return setSortBy(state, action);
    case CLOSE_MODALS:
      return closeModals(state);
    case SET_SITE_CONFIG:
      return setSiteConfig(state, action);
    case SET_FILTERS_AVAILABILITY:
      return setFiltersAvailability(state, action);
    case SET_SEARCH_THIS_AREA_CLICKED:
      return setSearchAreaFlagClicked(state,action);
    case SET_SEARCHADDRESS_FROM_GEOCODER:
      return setSearchAddressFromGeoCoder(state, action);
    case SET_STATIC_PLACE_COORDS:
      return setStaticPlaceCoords(state, action);
    default:
      return state;
  }
};

export default appReducer;
