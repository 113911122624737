import React, {
  Fragment, useContext, useState, useEffect
} from 'react';
import ReactGA from 'react-ga4';
import { Flex, Box } from 'rebass';
import { SpinLoader } from 'react-css-loaders';
import 'styled-components/macro';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import get from 'lodash/get';
import theme from 'styles/theme';
import { DEFAULT_COORDS, MAIN_PAGE_VIEW, DEFAULT_MILESAWAY, DEFAULT_ZOOM } from 'constants/general';

import USER_MARKER from 'assets/images/icons/markers/user-marker.svg';
import PLACE_MARKER from 'assets/images/icons/markers/place-marker.svg';
import FACEBOOK_ICON from 'assets/images/icons/socials/facebook.svg';
import INSTAGRAM_ICON from 'assets/images/icons/socials/instagram.svg';
import TWITTER_ICON from 'assets/images/icons/socials/twitter.svg';
import LINKEDIN_ICON from 'assets/images/icons/socials/linkedin.svg';

import useDataApi from 'hooks/useDataApi';
import usePosition from 'hooks/usePosition';
import { GlobalStateContext } from 'context/global-context';
import keyboardCodes from 'constants/keyboardCodes';
import { setPlaceCoords, defaultZoomUp, setSearchThisAreaClicked,setSearchAddressFromGeoCoder } from 'actions';
import { isNull } from 'lodash';

import { closeModals, setUserManaualZoom, setUserCurrentLocation, hideLocationDetails, setMapLocationWithZoom, setStaticPlaceCoords } from 'actions';
import {
  Header,
  LocationMarker,
  Results,
  Search,
  LocationInfo,
  SearchArea,
  SendToPhoneModal,
  SendToEmailModal,
  ContactUsModal,
  Marker,
  MapControls,
  SearchDetails,
  PopOutWindow,
  ErrorModal
} from './components';
import Map from './shared/Map';
import { setCurrentUserCoords, } from 'actions';
import { ThemeContext } from 'styled-components';
import isMobileDevice from '../../utils/deviceDetector';
// required in order to avoid axe RenderToString issue
const isTestEnvironment = process.env.NODE_ENV === 'test';

const { ESCAPE } = keyboardCodes.FUNCTION;
const { TAB } = keyboardCodes.ALPHANUMERIC.FUNCTIONAL;

const KEYBOARD_NAVIGATION_CLASS = 'keyboard-navigation';

const APP_CONFIG = {
  NETWORK_ID: null,
  LOCATION_RADIUS: 10,
  shouldDispalyConfiguredLocation: false
};

const initializeVisitorsCount = (NETWORK_ID) => {
  // eslint-disable-next-line no-undef
  const { APP_GOOGLE_ANALYTICS_DIMENSION } = window.CRD_GLOBAL;
  ReactGA.ga('send', 'pageview', MAIN_PAGE_VIEW, { [APP_GOOGLE_ANALYTICS_DIMENSION]: NETWORK_ID });
};

const Scene = () => {
  const { state, dispatch } = useContext(GlobalStateContext);
  const { latitude, longitude, error } = usePosition();
  const [googleMap, setGoogleMap] = useState(null);
  const [isPlaceEntered, setIsPlaceEntered] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [isappliedBounds, setIsappliedBounds] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [centerLat, setCenterLattitude] = useState('');
  const [centerLong, setCenterLongitude] = useState('');
  const [searchValue, setSearchValue] = useState();
  const [configuration, setConfiguration] = useState({});
  const colors = get(useContext(ThemeContext), 'colors', {});
  const {
    currentUserCoords,
    isStaticPlaceCoords
  } = state;
  const globalKeyDownHandler = ({ key }) => {
    if (key === ESCAPE) {
      dispatch(closeModals());
    }

    if (key === TAB) {
      // eslint-disable-next-line no-undef
      document.body.classList.add(KEYBOARD_NAVIGATION_CLASS);
    }
  };

  const globalPointerHandler = () => {
    // eslint-disable-next-line no-undef
    document.body.classList.remove(KEYBOARD_NAVIGATION_CLASS);
  };

  useEffect(() => {
    setInitialLoad(true)
    const siteConfig = get(state, 'siteConfig', {});
    var url = new URL(window.location.href);
    const newParams = new URLSearchParams();
    for (const [name, value] of url.searchParams) {
      newParams.append(name.toLowerCase(), value);
    }
    var searchText = newParams.get("addtxt");
    setSearchValue(searchText)
    if (!isEmpty(siteConfig)) {
      const {
        OnLoadLatitude,
        OnLoadLongitude,
        AtmNetworksId,
        DefaultMiles,
        RgbColor,
        PageTitle,
        GpsLogoNm,
        ISATMCOUNTSDATA,
        Twitter,
        Instagram,
        Facebook,
        LinkedIn
      } = siteConfig;

      if (OnLoadLatitude && OnLoadLongitude) {
        APP_CONFIG.OFFICE_CENTER_LOCATION = {
          lng: parseFloat(OnLoadLongitude),
          lat: parseFloat(OnLoadLatitude)
        };
      }
      APP_CONFIG.NETWORK_ID = AtmNetworksId;
      APP_CONFIG.LOCATION_RADIUS = DefaultMiles;
      APP_CONFIG.THEME_COLOR = RgbColor;
      APP_CONFIG.PAGE_TITLE = PageTitle;
      APP_CONFIG.PAGE_LOGO = GpsLogoNm;
      APP_CONFIG.ISATMCOUNTSDATA = ISATMCOUNTSDATA;
      APP_CONFIG.socials = [
        { label: 'Facebook', src: FACEBOOK_ICON, href: Facebook },
        { label: 'Instagram', src: INSTAGRAM_ICON, href: Instagram },
        { label: 'Twitter', src: TWITTER_ICON, href: Twitter },
        { label: 'LinkedIn', src: LINKEDIN_ICON, href: LinkedIn },
       
      ].filter(social => !isEmpty(social.href));
      setConfiguration(APP_CONFIG);
      // eslint-disable-next-line no-undef
      window.CRD_GLOBAL.IS_FILTERS_FEATURE_ENABLED = true;
      // eslint-disable-next-line no-undef
      window.CRD_GLOBAL.NETWORK_ID = AtmNetworksId;

      initializeVisitorsCount(AtmNetworksId);
    }
  }, [state.siteConfig]);

  useEffect(() => {
    const { placeCoords } = state;
    if (placeCoords) {
      state.mapCenter = {
        ...placeCoords
      };
    }
  }, [state.placeCoords]);

  useEffect(() => {
    // to handle anychange on usercords
    const { userCoords, zoomLevel } = state;
    if (userCoords && Object.keys(userCoords).keys.length > 0) {
      state.mapCenter = {
        ...userCoords
      };
    }
    if (zoomLevel) {
      state.zoom = zoomLevel
    }
  }, [state]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', globalKeyDownHandler);

    // eslint-disable-next-line no-undef
    document.addEventListener('pointerdown', globalPointerHandler);

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('keydown', globalKeyDownHandler);

      // eslint-disable-next-line no-undef
      document.removeEventListener('pointerdown', globalPointerHandler);
    };
  });

  // eslint-disable-next-line no-undef
  const { API_URL } = window.CRD_GLOBAL;

  let preDefinedLatitude;
  let preDefinedLongitude;

  if (!isNull(error)) {
    if (configuration && configuration.OFFICE_CENTER_LOCATION) {
      if (configuration.OFFICE_CENTER_LOCATION.lat && configuration.OFFICE_CENTER_LOCATION.lng) {
        preDefinedLatitude = configuration.OFFICE_CENTER_LOCATION.lat;
        preDefinedLongitude = configuration.OFFICE_CENTER_LOCATION.lng;
      }
    } else {
      preDefinedLatitude = DEFAULT_COORDS.LAT;
      preDefinedLongitude = DEFAULT_COORDS.LNG;
    }
  } else {
    if (latitude) {
      preDefinedLatitude = latitude;
      preDefinedLongitude = longitude;
    }
  }


  const [{ data, countData, isLoading }] = useDataApi(API_URL, {
    geolocationDefined: !isUndefined(latitude),
    latitude: preDefinedLatitude,
    longitude: preDefinedLongitude,
    NETWORK_ID: APP_CONFIG.NETWORK_ID,
    LOCATION_RADIUS: APP_CONFIG.LOCATION_RADIUS,
    initialPageIndex: 1
  });

  if (!isUndefined(latitude) && state.siteConfig.isSiteConfigLoaded) {
    if (!data.length) {
      state.mapCenter = {
        lat: get(state, ['placeCoords', 'lat'], parseFloat(preDefinedLatitude)),
        lng: get(state, ['placeCoords', 'lng'], parseFloat(preDefinedLongitude))
      };
    }
  }
/** handling search area button */
    useEffect(() => {
      setIsPlaceEntered(true)
      /**increase the zoom level when distance between atm center are small to each other */
    if(data.length>0){
      setIsappliedBounds(false)
      setUpZoomforNearestAtms()    
    }  
  }, [data]);

  const updateMapZoomandCenter = () => {
    var bounds =  new window.google.maps.LatLngBounds();
    const { lat, lng } = currentUserCoords;
        var marker, i;
        for (i = 0; i < data.length; i++) {  
          marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(data[i].lat, data[i].lng)
          });
        bounds.extend(marker.getPosition()); 
      }
      if(lat && lng){
      bounds.extend(new window.google.maps.LatLng(lat, lng))
      }
    if(bounds && googleMap){
    googleMap.fitBounds(bounds);
    }
  }
  function calculatePercent(percent){
    return (percent / 100) * data.length;
  }
  const  setUpZoomforNearestAtms = () => {
    const filterArray = data.filter(location => location.milesAway < DEFAULT_MILESAWAY)
  /** 50% Results less than 3 miles radius- Zoom level + 2 */
  if (filterArray.length > calculatePercent(50) && filterArray.length < calculatePercent(90)) {
      dispatch(setUserManaualZoom(DEFAULT_ZOOM+2));
     }
     /** 90% Results less than 3 miles radius- Zoom level + 3 */
     else if(filterArray.length > calculatePercent(90)){
      dispatch(setUserManaualZoom(DEFAULT_ZOOM+3));
     }
     else{
      setIsappliedBounds(true)
      updateMapZoomandCenter() 
     }
  }
  const renderUserMarker = () => {
    if (latitude && longitude && !error) {
      return (
        <Marker
          key="user-marker"
          lat={latitude}
          lng={longitude}
          tip={Math.random().toString()}
          alt="User marker"
          imgSrc={USER_MARKER}
        />
      );
    }

    return null;
  };

  const renderLocationsMarkers = () => map(data, (location) => {
    const { id, lat, lng } = location;

    return (
      <LocationMarker
        activeLocationId={state.location.id}
        key={id}
        lat={lat}
        lng={lng}
        location={location}
        color={configuration.THEME_COLOR}
      />
    );
  });

  const renderPlaceMarker = () => {

    const lat = get(state, ['placeCoords', 'lat'], null);
    const lng = get(state, ['placeCoords', 'lng'], null);
    if (lat && lng ) {
      return (
        <Marker
          key="place-marker"
          imgSrc={PLACE_MARKER}
          lat={lat}
          lng={lng}
          tip={Math.random().toString()}
          styles={{
            transform: 'translate(-55%,-100%)',
            cursor: 'default',
            zIndex: 1
          }}
          alt="Search Location"
        />
      );
    }

    return null;
  };

  const onPlaceSubmit = () => {
    dispatch(hideLocationDetails())
    setIsPlaceEntered(true)
  };
  /** Search this area handler */
  const onClick = () => {
    dispatch(setSearchThisAreaClicked(true));
    dispatch(hideLocationDetails());
    setShowSearchButton(false)
    setIsPlaceEntered(true)
    const lat = centerLat;
    const lng = centerLong;
    dispatch(setPlaceCoords({ lat, lng }));
    dispatch(setCurrentUserCoords({ lat, lng }));
    //setting geocoder address to empty to avoid calling API
    dispatch(setSearchAddressFromGeoCoder(''));
    dispatch(defaultZoomUp());
  };

  const updateUserMapCenter = (lat, lng) => {
    if (parseFloat(lat) == parseFloat(centerLat)) {
      setShowSearchButton(false)
    }
    if ((parseFloat(lat) !== parseFloat(latitude)
      && parseFloat(lng) !== parseFloat(longitude))) {
      if (!isUndefined(latitude)) {
        dispatch(setMapLocationWithZoom({ lat, lng }));
        setCenterLattitude(lat)
        setCenterLongitude(lng)
      }
      APP_CONFIG.shouldDispalyConfiguredLocation = true;
     }
     //setbound property  is enabled so need to keep red pin in a intial position
     if(!isappliedBounds && isStaticPlaceCoords){
     //tracking current Lat and Long
     dispatch(setPlaceCoords({ lat, lng }));// handles to show red pin in the center
    }
    dispatch(setStaticPlaceCoords(false));

  };

  //dispactching  zoom level once changed to set in state
  const updateUserManualZoom = (manualZoom) => {
    if (initialLoad) {
      setInitialLoad(false)
    }
    else {
      if (!isLoading) {
        setShowSearchButton(true)
      }
    }
    if(isPlaceEntered)
    {
      setShowSearchButton(false)
      setIsPlaceEntered(false)
    }
    setIsappliedBounds(false)
    dispatch(setUserManaualZoom(manualZoom));
  }

  const hideSearchBtn = () => {
    setShowSearchButton(false)
  }
  return (
    <Fragment>
      <Box
        css={{
          position: 'relative',
          height: '100%',
          width: '100%'
        }}
      >
        {isLoading && (
          <Box css={{
            position: 'absolute',
            zIndex: 2,
            minHeight: '100px',
            height: '100%',
            width: '100%',
            bottom: 0,
            left: 0,
            background: 'grey',
            opacity: '0.5',
            display: 'flex'
          }}
          >
            <Box
              css={{
                display: 'flex',
                margin: 'auto',
                minHeight: '120px'
              }}
              alt="Locatorsearch map loading"
              aria-label='Locatorsearch map loading'
              role="img"
            >
              <SpinLoader background="white" color={theme.colors.grey[0]} duration="1" size="2" />
            </Box>
          </Box>
        )}
        <Map
          zoom={state.zoom}
          mapCenter={state.mapCenter}
          onMapLoaded={setGoogleMap}
          onUserChangedMapCenter={updateUserMapCenter}
          onUserChangedZoom={updateUserManualZoom}
        >
          {[
            renderUserMarker(),
            renderPlaceMarker(),
            ...renderLocationsMarkers()
          ]}
        </Map>
        <Flex
          pt={[0, '10px']}
          pl={[0, '65px']}
          pr={[0, '30px']}
          width= {isMobileDevice() ? [1, 'fit-content'] : ['fit-content']}
          css={{
            position: 'absolute',
            top: 0,
            left: 0
          }}
        >
          <Box
            width="100%"
            flexWrap='nowrap'
          >
            {
              configuration.PAGE_LOGO ? (
                <Header
                  brandName={configuration.PAGE_TITLE}
                  src={configuration.PAGE_LOGO}
                  location={state.location}
                  socials={configuration.socials}
                />
              ) : null
            }
          </Box>
          
        </Flex>
        <Box
            css={{
              
              position: 'absolute',
              top: configuration.PAGE_LOGO ? 60 : 0,
              left: 0,
              width: '100%'
              // [`@media all and (max-width: ${theme.breakpoints[0]}px)`]: {
              //   paddingTop: configuration.PAGE_LOGO ? 60 : 0,
              // }
            }}
          >
            {showSearchButton && <SearchArea
              onClick={onClick}
              styles={{
                backgroundColor: 'white',
                color: 'black',
                borderColor: 'black',
                borderRadius: '75px',
                padding: '7px 15px',
                // marginRight: '15px',
                width: '150px',
                // marginTop: '5px',
              }}
            />}
          </Box>
        <Flex
          pt={[0, '10px']}
          pl={[0, '0px']}
          pr={[0, '10px']}
          width={[1, 'fit-content']}
          css={{
            position: 'absolute',
            top: 0,
            right: 0
          }}
        >
          {
            configuration.ISATMCOUNTSDATA ? (<SearchDetails />) : null
          }
        </Flex>

        <Flex
          width={1}
          justifyContent="flex-end"
          flexDirection={['column-reverse', 'row']}
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            paddingBottom: 0,
            marginBottom: '25px',
            
          }}
          p={['small', 'medium']}
          pb="mediumX"
        >
          {' '}
          <MapControls googleMap={googleMap} />
          <LocationInfo hideSearchBtn={hideSearchBtn} />
          <Search
            isPlaceEntered={isPlaceEntered}
            searchData={searchValue}
            onPlaceSubmit={onPlaceSubmit}
            color={configuration.THEME_COLOR}
            networkId={configuration.NETWORK_ID}
            isAtmCountsAvailable={configuration.ISATMCOUNTSDATA}
          />
          <Results results={data} countData={countData} />
        </Flex>
      </Box>

      {!isTestEnvironment && <SendToEmailModal />}
      {!isTestEnvironment && <SendToPhoneModal />}
      {!isTestEnvironment && <ContactUsModal />}
      {!isTestEnvironment && <PopOutWindow />}
      {!isTestEnvironment && <ErrorModal />}
    </Fragment>
  );
};

export default Scene;
