import React, {useEffect, forwardRef, useRef} from 'react';
import { Flex, Text } from 'rebass';
import 'styled-components/macro';
import useFontScalingArr from "../../../../../hooks/useFontScalingArr";

const LocationTimeInfo = forwardRef((props, ref) => {
  var { time } = props;

  if (!time.length) return null;
  time = time.replace(/\./g,'')
  const [from, to] = time.split('-');
  const isRangeFull = Boolean(from && to);
  const elementsRef= useRef([]);

  React.useImperativeHandle(ref, ()=>({
    elementsRef
  }));

  useFontScalingArr(elementsRef.current);
  useEffect(()=>{
    ref.current={
      texts:elementsRef.current
    }
  },[ref]);
  return (
    <React.Fragment>
      <Text width='70px' ref={(element)=>elementsRef.current.push(element)}>
        {from}
      </Text>
      {
        isRangeFull ? (
          <Flex>
            <Text
              alignSelf="center"
              css={{
                width: '20px',
                height: '1px',
                backgroundColor: '#E2E2E2'
              }}
            />
          </Flex>
        ) : null
      }
      <Text ml="20px" ref={(element)=>elementsRef.current.push(element)}>
        {to}
      </Text>
    </React.Fragment>
  );
});

LocationTimeInfo.defaultProps = {
  time: ''
}

export default LocationTimeInfo;
