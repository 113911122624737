import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
:root {
  --vh: 1vh;
    overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

:focus {
  outline: 0;
}

.keyboard-navigation *:focus {
  outline: rgb(59, 153, 252) solid 2px;
  outline-offset: 2px;
}
  
body{
  width: 100%;
  height: 100%;
  
  -webkit-tap-highlight-color: transparent;

  @font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display'),
         url('/fonts/SF-Pro-Display-Regular.woff') format('woff'),
         url('/fonts/SF-Pro-Display-Regular.otf') format('opentype'),
         url('/fonts/SF-Pro-Display-Regular.ttf') format('truetype')
  }
  
   @font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: bold;
    src: local('SF Pro Display'),
          url('/fonts/SF-Pro-Display-Bold.woff') format('woff'),
         url('/fonts/SF-Pro-Display-Bold.otf') format('opentype'),
         url('/fonts/SF-Pro-Display-Bold.ttf') format('truetype')
  }
  
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-weight: normal;
  }
`;

export default GlobalStyles;
