import React, { useContext, useState } from 'react';
import { Box } from 'rebass';
import 'styled-components/macro';
import isNil from 'lodash/isNil';

import theme from 'styles/theme';
import usePosition from 'hooks/usePosition';
import keyboardCodes from 'constants/keyboardCodes';
import { setUserCurrentLocation } from 'actions';
import {
  DECREASE_ZOOM_LEVEL,
  INCREASE_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL
} from 'constants/general';

import ControlButton, { ControlButtonWithFocus } from './ControlButton';
import { GlobalStateContext } from '../../../../context/global-context';
import isMobileDevice from '../../../../utils/deviceDetector';

const MapControls = ({ googleMap }) => {
  const { dispatch } = useContext(GlobalStateContext);
  const { latitude: lat, longitude: lng } = usePosition();
  const {
    LEFT, UP, RIGHT, DOWN
  } = keyboardCodes.ARROW_PAD;

  const { PLUS, MINUS, YOUR_LOCATION, MAP_TYPE } = ControlButton.types;
  const getCurrentZoom = () => googleMap.getZoom();
  const [roadmapType, setroadmapType] = useState(true);
  const handleZoomIn = () => {
    const zoom = getCurrentZoom() + INCREASE_ZOOM_LEVEL;
    googleMap.setZoom(zoom);
  };

  const handleZoomOut = () => {
    const zoom = getCurrentZoom() - DECREASE_ZOOM_LEVEL;
    googleMap.setZoom(zoom);
  };

  const handleOnKeyDown = ({ key }) => {
    if ([LEFT, UP].includes(key)) handleZoomIn();
    if ([RIGHT, DOWN].includes(key)) handleZoomOut();
  };

  const handleNavigateToCurrentLocation = () => {
    const mapCenter = { lat, lng };
    googleMap.panTo(mapCenter);
    googleMap.setZoom(MAX_ZOOM_LEVEL);

    dispatch(setUserCurrentLocation(mapCenter));
  };
  //This function handles satellite Toggle view
  const toggleMapType = () => {
    if (roadmapType) {
      //hybrid or satellite
      googleMap.setMapTypeId('hybrid');
      setroadmapType(false)
    }
    else {
      //default view
      googleMap.setMapTypeId('roadmap');
      setroadmapType(true)
    }
  }

  return (
    <Box
      css={{
        left: '10px',
        position: 'absolute',
        top: '-50vh',

        [`@media all and (min-width: ${theme.breakpoints[0]}px)`]: {
          left: '20px'
        },

        [`@media all and (max-height: 500px)`]: {
          display: isMobileDevice() ? 'none' : '',
          top: isMobileDevice() ? '-50vh' : '-58vh',
        }
      }}
    >
      <Box
        css={{
          position: 'absolute',
          top: '0',

          [`@media all and (max-width: ${theme.breakpoints[3]}px)`]: {
            top: '0px'
          },
          [`@media screen and (max-height: 400px)`]: {
            display: !isMobileDevice() ?'none':''           
          }
        }}
      >
        <Box
          mb="1px"
        >
          <ControlButtonWithFocus
            type={PLUS}
            onClick={handleZoomIn}
            onKeyDown={handleOnKeyDown}
            toolTip='Zoom In'
            alt='Zoom In'
          />
        </Box>
        <ControlButtonWithFocus
          type={MINUS}
          onClick={handleZoomOut}
          onKeyDown={handleOnKeyDown}
          toolTip='Zoom Out'
          alt='Zoom Out'
        />
      </Box>
      { !isNil(lat) && <Box
        css={{
          position: 'absolute',
          top: '100px',

          [`@media all and (max-width: ${theme.breakpoints[3]}px)`]: {
            top: '125px'
          }
        }}
      >
        {
          !isNil(lat)
            ? (
              <ControlButtonWithFocus
                type={YOUR_LOCATION}
                onClick={handleNavigateToCurrentLocation}
                toolTip='Use Current Location'
                alt='current location'
              />
            ) : null
        }
      </Box>}
      <Box
        css={{
          position: 'absolute',
          top: !isNil(lat) ? '148px' : '90px',

          [`@media all and (max-width: ${theme.breakpoints[3]}px)`]: {
            top: !isNil(lat) ? '172px' : '90px',
          },
          [`@media all and (max-height: ${theme.breakpoints[3]}px)`]: {
            top: !isMobileDevice() ? '70px':'',
          },
          [`@media screen and (max-height: 400px)`]: {
            display: !isMobileDevice() ?'none':''           
          }
        }}
      >
        <ControlButtonWithFocus
          type={MAP_TYPE}
          onClick={toggleMapType}
          roadmapType={roadmapType}
          toolTip= {roadmapType ? 'Satellite View' : 'Map View'}
          alt= {roadmapType ? 'Satellite View' : 'Map View'}
        />

      </Box>
    </Box>
  );
};

export default MapControls;
